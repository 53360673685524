import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar } from 'react-admin';
import { Box, useMediaQuery, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
// import Logo from '../assets/logos/header logo.png'
import ICSLogo from '../assets/logos/ics logo.png';
import { AppBarToolbar } from './AppBarToolbar';
var CustomAppBar = function () {
    var isLargeEnough = useMediaQuery(function (theme) {
        return theme.breakpoints.up('sm');
    });
    return (_jsx(Box, { flexGrow: 1, children: _jsxs(AppBar, { toolbar: _jsx(AppBarToolbar, {}), sx: {
                backgroundColor: "#1561a5",
            }, children: [isLargeEnough && (_jsx(Tooltip, { title: "go to Dashboard", arrow: true, children: _jsx(Link, { to: "/admin/dashboard", style: { height: 68 }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 68 }) }) })), isLargeEnough && _jsx(Box, { component: "span", sx: { flex: 1 } })] }) }));
};
export default CustomAppBar;
