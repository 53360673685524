var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Divider, Typography, Grid, Button } from '@mui/material';
import { general } from '../utils/componentWizard';
import SelectedRowLeftColumn from './SelectedRowLeftColumn';
import FileUpload from '../utils/FileUpload/FileUpload';
import FPComp from './fp/FP';
import DataImport from './dataImport/dataImport';
import AIResultsList from './aiResults/AIResults';
import EditDialog from '../subjectPage/EditDialog';
import ExamEditDialog from './ExamEditDialog';
import { blueIDHelper } from '../utils/helper';
import dummyData, { FPAIdummyData } from './fp/helper/dummyData';
// import DataTable3 from './genPdf/genPdfSample2/Table3';
import AnalyzeAIBox from './fp/AnalyzeAIBox';
var isNotEven = function (numb) {
    return numb % 2 !== 0;
};
var ComponentSelection = function (_a) {
    var bgColor = _a.bgColor, text = _a.text, setCurrentSubjectSection = _a.setCurrentSubjectSection, setImage = _a.setImage, setDummyCompList = _a.setDummyCompList;
    var translate = useTranslate();
    return (_jsx(Grid, { item: true, xs: 4, paddingY: 1, display: "flex", justifyContent: "center", alignItems: "center", style: { backgroundColor: bgColor !== null && bgColor !== void 0 ? bgColor : "white" }, onClick: function () {
            if (text === 'Data Import') {
                return;
            }
            if (text === "AI Results") {
                setDummyCompList([{ name: "AI Results", bgColor: "white" }]);
            }
            setImage(null);
            setCurrentSubjectSection(text);
        }, children: _jsx(Typography, { variant: "body1", children: translate("components.".concat(text.toLowerCase().replace(' ', '_'))) }) }));
};
var ExamList = function (props) {
    var _a, _b;
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState("AI Results"), currentSubjectSection = _d[0], setCurrentSubjectSection = _d[1]; //[back][for fp] - make it "AI Results" later
    var _e = useState([{ name: "AI Results", bgColor: "white" },
        // { name: "Data Import" },
        // { name: "Questionnaire Results" },
        // { name: "FP" },
        // { name: "OCT" }, { name: "FP + OCT" }
    ]), dummyCompList = _e[0], setDummyCompList = _e[1];
    var _f = useState({
    // examFromDate: splitDateTimeHelper(getTodaysDateChinaTimeZone()),
    // examToDate: splitDateTimeHelper(getTodaysDateChinaTimeZone())
    }), filters = _f[0], setFilters = _f[1];
    // left panel
    var _g = useState([]), selectedRow = _g[0], setSelectedRow = _g[1];
    var _h = useState(false), dialogOpened = _h[0], setDialogOpened = _h[1];
    var _j = useState(false), examDateDialogOpened = _j[0], setExamDateDialogOpened = _j[1];
    // AI result
    var _k = useState(), examDialogData = _k[0], setExamDialogData = _k[1]; //onclick AI results list - the dialog 
    var _l = useState(false), examDialogOpened = _l[0], setExamDialogOpened = _l[1]; //onclick AI results list - open dialog
    var _m = useState({ hkid: null, hkid_id: null }), selectedHKID = _m[0], setSelectedHKID = _m[1];
    var location = useLocation();
    var navigate = useNavigate();
    // for Image upload
    var _o = useState([]), curRowImgs = _o[0], setCurRowImgs = _o[1]; //imgs array - only img in exams table's row 
    // const [curRowImgs, setCurRowImgs] = useState([ //[back][for fp]   
    //     {
    //         right: true,
    //         gradable: true,
    //         comment: "",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 1,
    //         center: true
    //     },
    //     {
    //         right: false,
    //         gradable: true,
    //         comment: "",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 2,
    //         center: true
    //     },
    //     {
    //         right: false,
    //         gradable: true,
    //         comment: "",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 3,
    //         center: false
    //     },
    //     {
    //         right: false,
    //         gradable: false,
    //         comment: "hi",
    //         img: "",
    //         exam_id: "",
    //         fp_id: 4,
    //         center: false
    //     },
    // ]);
    var _p = useState(null), currentImgUploadRow = _p[0], setCurrentImgUploadRow = _p[1]; //row in exams table
    var _q = useState(null), image = _q[0], setImage = _q[1]; //[back][for fp] - make it null later
    var _r = useState(null), imageInfos = _r[0], setImageInfos = _r[1];
    var _s = useState(null), analyzedResultInfo = _s[0], setAnalyzedResultInfo = _s[1];
    var _t = useState(dummyData), analyzedImagesData = _t[0], setAnalyzedImagesData = _t[1];
    var _u = useState(false), isLoadingUpload = _u[0], setIsLoadingUpload = _u[1];
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var _v = useState([]), data = _v[0], setData = _v[1];
    var resource = "exams";
    //for AI analyze for image upload
    var _w = useState(false), isLoadingFPAI = _w[0], setIsLoadingFPAI = _w[1];
    var _x = useState(60), loadingFPAITime = _x[0], setLoadingFPAITime = _x[1];
    document.body.style.zoom = "100%";
    useEffect(function () {
        var _a, _b, _c;
        //set up filters
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        if (sessonStorageJSON) {
            setFilters(__assign(__assign({}, filters), sessonStorageJSON));
        }
        var currentHKIDID = (_b = localStorage.getItem("hkid_id")) !== null && _b !== void 0 ? _b : null;
        var currentHKID = (_c = localStorage.getItem("hkid")) !== null && _c !== void 0 ? _c : null;
        setSelectedHKID({ hkid: currentHKID, hkid_id: currentHKIDID });
        var locationData = location === null || location === void 0 ? void 0 : location.state;
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign(__assign({}, filters), sessonStorageJSON), { subject_id: currentHKIDID })));
        // if is redirect, set up the redirected data
        if (locationData) {
            setSelectedRow(__assign({ dialogType: "Edit" }, locationData));
        }
        // if is refresh/enter url, get one
        else {
            var getOneParams = { id: currentHKIDID };
            dataProvider.getOne("subjects", getOneParams)
                .then(function (data) {
                setSelectedRow(__assign(__assign({ dialogType: "Edit" }, data.data), { hkid_id: currentHKIDID }));
                refresh();
                notify('Exam fetched');
            });
        }
        // set up table data
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "exam_date", order: "DESC" },
            filter: {
                // exam_id: currentHKIDID,
                // hkid: currentHKID,  //[back] later switch to hkid
                // createby: 1,
                // givenId: currentHKIDID, //[back] later switch to real id - id for db, not hkid
                // // ...filters
                subject_id: selectedRow.subject_id,
            }
        };
        //fetch all data first for left panel
        var noFilterParams = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "exam_date", order: "DESC" },
            filter: {
                subject_id: currentHKIDID,
            }
        };
        dataProvider.getListNoFilter(resource, noFilterParams)
            .then(function (rtnData) {
            var _a;
            console.log(rtnData === null || rtnData === void 0 ? void 0 : rtnData.data, '==hi 123');
            setData((_a = rtnData === null || rtnData === void 0 ? void 0 : rtnData.data) !== null && _a !== void 0 ? _a : []);
            refresh();
            //fetch data with params after for table (AI Results)
            params.filter = __assign(__assign({}, params.filter), filters);
            return dataProvider.getList(resource, params);
        }).then(function () {
            refresh();
            setIsLoading(false);
            notify('Exam fetched');
        });
    }, []);
    // useEffect(() => {
    //     console.log(" ")
    // }, [filters])
    // for AI result list [section]
    var closeDialog = function () {
        setDialogOpened(false);
    };
    // after edit dialog pressing "submit"
    var reloadRecord = function (values, dialogType) {
        try {
            var params = {
                data: {
                    id: values.id,
                    subject_hkid: values.hkid, //[back] later switch to hkid,
                    hkid_id: selectedHKID.hkid_id,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    gender: values.gender,
                    educationLevel: values.educationLevel,
                    dateOfBirth: values.dateOfBirth,
                    createby: 1,
                    givenId: values.id //[back] later switch to real id - id for db, not hkid
                }
            };
            dataProvider.update("subjects", params)
                .then(function () {
                refresh();
                notify('Subject updated');
            });
            setSelectedRow(params.data);
        }
        catch (err) {
            console.log(err, '==err');
            // dispatch(actionTypes.SNACKBAR_ERROR);
        }
    };
    // for left panel exam [section]
    var closeExamDateDialog = function () {
        setExamDateDialogOpened(false);
    };
    var openExamDateDialog = function () {
        setExamDateDialogOpened(true);
    };
    var reloadCreateExamDateRecord = function (values, viewCase) {
        var _a;
        try {
            console.log('==log create exam record', values);
            setCurrentImgUploadRow(__assign(__assign({}, values), { hkid_id: selectedHKID.hkid_id }));
            if (((_a = values === null || values === void 0 ? void 0 : values.fp_imgs) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                // preview
                setCurrentSubjectSection("");
                setCurRowImgs(values.fp_imgs);
                setImage(values.fp_imgs[0]);
            }
            else {
                // upload
                setCurrentSubjectSection("Data Import");
                if (viewCase === 'preview') {
                    setCurRowImgs(values.fp_imgs);
                }
            }
        }
        catch (err) {
            console.log(err, '==err');
        }
    };
    // for AI results list [section]
    var closeExamEditDialog = function () {
        setExamDialogOpened(false);
    };
    var openExamEditDialog = function (record) {
        var _a, _b, _c, _d;
        console.log('==log opennn', record);
        setExamDialogData({
            dialogType: (record === null || record === void 0 ? void 0 : record.id) ? "Edit" : "Create",
            id: (_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : null,
            hkid: (_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.hkid) !== null && _b !== void 0 ? _b : null,
            ai_model: (_c = record === null || record === void 0 ? void 0 : record.ai_model) !== null && _c !== void 0 ? _c : null,
            eye: (_d = record === null || record === void 0 ? void 0 : record.eye) !== null && _d !== void 0 ? _d : null,
            hkid_id: selectedHKID.hkid_id
        });
        // formik?.current?.resetForm();
        setExamDialogOpened(true);
    };
    var reloadExamEditRecord = function (values, dialogType, submitType) {
        try {
            console.log('==log submit from dialog', selectedRow, values, dialogType);
            var params = {
                data: {
                    subject_hkid: selectedHKID.hkid,
                    // hkid: values.hkid,
                    // hkid: values.hkid_id,
                    exam_date: values.exam_date,
                    eye: values.eye,
                    // ai_model: values.ai_model,
                }
            };
            switch (dialogType) {
                case "Create":
                    params.data.eid = values.id;
                    // params.data.hkid = values.hkid;
                    params.data.hkid = values.hkid_id;
                    params.data.parentGivenId = values.hkid;
                    // params.data.parentGivenId = values.hkid_id;
                    params.data.givenId = values.id;
                    dataProvider.create(resource, params)
                        .then(function (returnData) {
                        var updatedData = __spreadArray([], data, true);
                        updatedData.push(returnData.data);
                        setData(updatedData);
                        // setCurrentSubjectSection("Data Import")
                        if (submitType === 'submitWithImport') {
                            reloadCreateExamDateRecord(returnData.data);
                            setDummyCompList([{ name: "AI Results", bgColor: "#efefef" },
                                { name: "Data Import", bgColor: "white" }]);
                        }
                        refresh();
                        notify('Exam added');
                    });
                    // if clicked import data
                    break;
                case "Edit":
                    if (values.ai_model) {
                        params.data.ai_model = values.ai_model;
                    }
                    ;
                    dataProvider.updateAttribute(resource, params, values.hkid_id, values.id)
                        .then(function () {
                        // [back] problem here
                        var fetchParams = {
                            pagination: { page: 1, perPage: 25 },
                            sort: { field: "exam_date", order: "DESC" },
                            filter: {
                                // exam_id: selectedRow.id,
                                // hkid: selectedRow.hkid,
                                // createby: 1,
                                // givenId: selectedRow.id,
                                // id: values.id
                                subject_id: selectedRow.id,
                            }
                        };
                        return dataProvider.getListNoFilter(resource, fetchParams);
                    })
                        .then(function (data) {
                        var _a;
                        console.log(selectedRow, selectedRow.id, data === null || data === void 0 ? void 0 : data.data, '==exams all data in edit');
                        setData((_a = data === null || data === void 0 ? void 0 : data.data) !== null && _a !== void 0 ? _a : []);
                        refresh();
                        notify('Exam updated');
                    }).catch(function (error) {
                        // Handle any errors that occurred
                        console.error(error);
                        notify('Exam update failed');
                    });
                    break;
            }
        }
        catch (err) {
            console.log(err, '==err');
            // dispatch(actionTypes.SNACKBAR_ERROR);
        }
    };
    var delExamEditAction = function (values) {
        console.log(values, '==log values');
        var params = {
            data: {
                eid: values.id,
                subject_hkid: values.subject_hkid, //[back] later switch to hkid
                // hkid: values.subject_hkid,
                hkid: values.hkid_id,
                // givenId: values.id,
                // parentGivenId: values.subject_hkid,
            }
        };
        dataProvider.deleteAttribute(resource, params, selectedHKID.hkid_id, values.id)
            .then(function () {
            var updatedData = data;
            var index = updatedData.findIndex(function (obj) { return obj.id === values.id; });
            if (index !== -1) {
                updatedData.splice(index, 1);
            }
            setData(updatedData);
            refresh();
            notify('Exam deleted');
        });
    };
    // left panel - onclick "preview" action
    var handlePreviewExamDate = function (values) {
        var examDate = new Date(values === null || values === void 0 ? void 0 : values.exam_date).toLocaleDateString('zh-CN');
        var sessionStorageParams = { "examFromDate": examDate, "examToDate": examDate, "subject_id": values.subject_id };
        // set examFromDate as filter only
        setFilters({});
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(sessionStorageParams));
        console.log('==here  232 values', values, sessionStorageParams);
        var params = {
            pagination: { page: 1, perPage: 25 },
            sort: { field: "exam_date", order: "DESC" },
            filter: __assign(__assign({}, sessionStorageParams), { subject_id: selectedRow.subject_id })
        };
        setFilters(sessionStorageParams);
        dataProvider.getList(resource, params)
            .then(function (data) {
            refresh();
            setFilters(sessionStorageParams);
            notify('Exam fetched');
        });
        // window.location.reload()
    };
    return (_jsxs(Grid, { container: true, display: "flex", children: [dialogOpened && _jsx(EditDialog, { opened: dialogOpened, data: selectedRow, 
                // openDialog={openDialog}
                closeDialog: closeDialog, reloadRecord: reloadRecord, translate: translate }), examDialogOpened && _jsx(ExamEditDialog, { opened: examDialogOpened, data: examDialogData, 
                // openDialog={openDialog}
                translate: translate, closeDialog: closeExamEditDialog, reloadRecord: reloadExamEditRecord }), _jsx(Grid, { item: true, lg: 3, xs: 12, marginTop: "2rem", children: _jsx(SelectedRowLeftColumn, { translate: translate, selectedRow: selectedRow, setSelectedRow: setSelectedRow, data: data, setDialogOpened: setDialogOpened, openExamEditDialog: openExamEditDialog, page: resource, handlePreviewExamDate: handlePreviewExamDate }) }), _jsxs(Grid, { item: true, lg: 9, xs: 12, padding: 2, sx: { marginTop: '1.1em' }, children: [_jsx(general.breadcrumb, { trail: ["Main", "".concat(translate("pages.subject.name")), "".concat((_a = blueIDHelper(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.hkid)) !== null && _a !== void 0 ? _a : "")] }), _jsx(Button, { onClick: function () {
                            var content = FPAIdummyData;
                            // TODO: print pdf
                            var examData = content.examData;
                            var analysisData = content.analysisData;
                            var url = '/admin/fp/report';
                            localStorage.setItem('report_cache_data', JSON.stringify(content));
                            var newWindow = window.open(url, '_blank');
                        }, children: "TEST SAVE CACHE FOR FP AI" }), _jsx(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "start", children: _jsx(Typography, { variant: "h5", sx: { paddingRight: '10px', fontWeight: "900", marginBottom: '0.5em' }, children: "".concat(translate("pages.subject.name"), " ").concat((_b = blueIDHelper(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.hkid)) !== null && _b !== void 0 ? _b : "") }) }), _jsx(Divider, {}), _jsx(Grid, { container: true, paddingY: 1, style: {}, children: dummyCompList.map(function (el, index) {
                            var _a;
                            return (_jsx(ComponentSelection, { text: el === null || el === void 0 ? void 0 : el.name, bgColor: (_a = el === null || el === void 0 ? void 0 : el.bgColor) !== null && _a !== void 0 ? _a : null, setImage: setImage, setCurrentSubjectSection: setCurrentSubjectSection, setDummyCompList: setDummyCompList }));
                        }) }), currentSubjectSection === 'OCT' && _jsx(FileUpload, {}), ((currentSubjectSection === 'Data Import' || image) && curRowImgs.length < 10) && _jsx(DataImport, { translate: translate, 
                        //check if have uploads
                        haveUploads: image ? true : false, setImage: setImage, image: image, imageInfos: imageInfos, setImageInfos: setImageInfos, analyzedResultInfo: analyzedResultInfo, setAnalyzedResultInfo: setAnalyzedResultInfo, analyzedImagesData: analyzedImagesData, isLoadingUpload: isLoadingUpload, currentImgUploadRow: currentImgUploadRow, setCurRowImgs: setCurRowImgs, curRowImgs: curRowImgs }), image && _jsx(FPComp, { translate: translate, setImage: setImage, image: image, imageInfos: imageInfos, setImageInfos: setImageInfos, analyzedResultInfo: analyzedResultInfo, analyzedImagesData: analyzedImagesData, setAnalyzedResultInfo: setAnalyzedResultInfo, setCurrentSubjectSection: setCurrentSubjectSection, curRowImgs: curRowImgs, setCurRowImgs: setCurRowImgs, currentImgUploadRow: currentImgUploadRow, selectedRow: selectedRow, isLoadingUpload: isLoadingUpload, setIsLoadingUpload: setIsLoadingUpload, setDummyCompList: setDummyCompList, loadingFPAITime: loadingFPAITime, setIsLoadingFPAI: setIsLoadingFPAI, setLoadingFPAITime: setLoadingFPAITime, isLoadingFPAI: isLoadingFPAI }), isLoadingFPAI && _jsx(AnalyzeAIBox, { loadingFPAITime: loadingFPAITime }), currentSubjectSection === 'AI Results' &&
                        _jsx(AIResultsList, { translate: translate, isLoading: isLoading, setIsLoading: setIsLoading, selectedRow: selectedRow, setSelectedRow: setSelectedRow, filters: filters, setFilters: setFilters, delExamEditAction: delExamEditAction, reloadCreateExamDateRecord: reloadCreateExamDateRecord, setCurRowImgs: setCurRowImgs, openExamEditDialog: openExamEditDialog, setData: setData, setDummyCompList: setDummyCompList })] })] }));
};
export default ExamList;
