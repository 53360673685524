//  MUI
var EyeMuiChoices = [
    { value: 'Left', label: 'Left' },
    { value: 'Right', label: 'Right' },
    { value: 'Both', label: 'Both' }
];
var AIModelMuiChoices = [
    { value: 'FP + OCT', label: 'FP + OCT' },
    { value: 'FP', label: 'FP' },
    { value: 'OCT', label: 'OCT' }
];
var RiskOfAdMuiChoices = [
    { value: 'HIgh', label: 'High' },
    { value: 'Low', label: 'Low' },
];
var StatusMuiChoices = [
    { value: 'Active', label: 'Active' },
    { value: 'Disabled', label: 'Disabled' },
];
export { EyeMuiChoices, AIModelMuiChoices, RiskOfAdMuiChoices, StatusMuiChoices, };
