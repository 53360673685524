var settingsStyles = {
    settingsContainer: {
        width: "100%",
        // backgroundColor: "red",
    },
    componentContainer: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        // margin: "1rem",
        marginRight: "1rem",
        padding: "1rem",
        borderRadius: "15px",
        boxShadow: "5px 5px lightgrey",
        '-moz-box-shadow': '0 0 3px lightgrey',
        '-webkit-box-shadow': '0 0 3px lightgrey',
        'box-shadow': '0 0 3px lightgrey',
        // backgroundColor: "red",
    },
    creditCard: {
        payBtn: {
            margin: "1rem",
            backgroundColor: "#4d6a95",
            borderRadius: "10px",
            fontSize: "1.4rem",
            position: "relative",
            overflow: "hidden",
            transition: "all 0.3s ease-in-out",
            "&:before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "0%",
                height: "0%",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "50%",
                transition: "all 0.5s ease-out",
            },
            "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: "0 10px 20px rgba(77, 106, 149, 0.4)",
                "&:before": {
                    width: "300%",
                    height: "300%",
                    top: "-150%",
                    left: "-150%",
                },
            },
            "&:active": {
                transform: "translateY(2px)",
            }
        },
        cancelBtn: {
            margin: "1rem",
            backgroundColor: "white",
            color: "black",
            borderRadius: "10px",
            fontSize: "1.4rem",
            position: "relative",
            overflow: "hidden",
            transition: "all 0.3s ease-in-out",
            "&:before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "0%",
                height: "0%",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                borderRadius: "50%",
                transition: "all 0.5s ease-out",
            },
            "&:hover": {
                transform: "translateY(-5px)",
                boxShadow: "0 10px 20px rgba(77, 106, 149, 0.4)",
                "&:before": {
                    width: "300%",
                    height: "300%",
                    top: "-150%",
                    left: "-150%",
                },
            },
            "&:active": {
                transform: "translateY(2px)",
            }
        }
    }
};
export { settingsStyles };
