import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate, } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { dbDateHelper, blueIDHelper } from '../utils/helper';
import { NORMAL_GRID_SPACE } from '../styles/styleConstants.data';
import Button from '@mui/material/Button';
import '../styles/globalStyle.css';
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
var RowItem = function (_a) {
    var leftText = _a.leftText, rightText = _a.rightText;
    return (_jsxs(Grid, { className: 'small-bottom-margin', item: true, xs: 12, display: "flex", alignContent: "center", justifyContent: "space-between", children: [_jsx(Typography, { variant: "body", children: leftText }), _jsx(Typography, { variant: "body", children: rightText })] }));
};
var RowItemTitle = function (_a) {
    var title = _a.title;
    return (_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { className: 'normal-text', children: title }) }));
};
var SelectedRowLeftColumn = function (_a) {
    var _b;
    var selectedRow = _a.selectedRow, setSelectedRow = _a.setSelectedRow, data = _a.data, openExamEditDialog = _a.openExamEditDialog, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page, handlePreviewExamDate = _a.handlePreviewExamDate;
    var translate = useTranslate();
    var navigate = useNavigate();
    console.log(selectedRow, '==selectedRow 123');
    return (_jsxs(Grid, { container: true, direction: 'row', rowGap: NORMAL_GRID_SPACE, children: [_jsx(Grid, { xs: 12, children: _jsx(SubjectTitle, { setSelectedRow: setSelectedRow, navigate: navigate, setDialogOpened: setDialogOpened, closeLeftPanel: closeLeftPanel, page: page }) }), _jsxs(Grid, { className: 'small-text', xs: 12, padding: 2, sx: {
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: '15px',
                }, children: [_jsx(Grid, { className: 'normal-bottom-margin', children: _jsx(RowItemTitle, { title: translate("pages.subject.fields.info") }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.hkid"), rightText: blueIDHelper(selectedRow.hkid) }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.userList.fields.organization"), rightText: (_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.organization) === null || _b === void 0 ? void 0 : _b.orgName }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.lastname"), rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.lastname }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.firstname"), rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.firstname }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.gender"), rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.gender }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.educationLevel"), rightText: selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.educationLevel }) }), _jsx(Grid, { className: 'small-bottom-margin', children: _jsx(RowItem, { leftText: translate("pages.subject.fields.dateOfBirth"), rightText: dbDateHelper(selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.dateOfBirth) }) })] }), _jsxs(Grid, { padding: 2, xs: 12, sx: {
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: '15px',
                }, children: [_jsxs(Grid, { className: 'normal-bottom-margin', display: "flex", flexWrap: "wrap", children: [_jsx(RowItemTitle, { title: translate("pages.exam.allExamDate") }), _jsx(Box, { display: "flex", justifyContent: "flex-end", width: "100%", paddingRight: 2, children: page === "exams" && (_jsxs(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: "#1976d2",
                                        cursor: "pointer",
                                        fontWeight: 500,
                                        opacity: "0.6",
                                        fontSize: "1.2em",
                                    }, onClick: openExamEditDialog, children: [_jsx(AddBoxIcon, { sx: { marginRight: '0.3em', fontSize: '1.2em' } }), _jsx(Typography, { className: 'small-text large-bold', children: translate("pages.exam.dialog.add_new_exam") })] })) })] }), data && data.map(function (el, idx) {
                        console.log('==data for dates', data);
                        return (_jsx(RowItem, { leftText: dbDateHelper(el.exam_date), rightText: _jsx(Grid, { style: { backgroundColor: "", color: "#487ca8" }, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: page === "exams" && _jsx(PageviewIcon, { onClick: function () { handlePreviewExamDate(el); } }) }) }));
                    })] })] }));
};
var SubjectTitle = function (_a) {
    var setSelectedRow = _a.setSelectedRow, navigate = _a.navigate, setDialogOpened = _a.setDialogOpened, closeLeftPanel = _a.closeLeftPanel, page = _a.page;
    var translate = useTranslate();
    return (_jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { children: _jsx(Typography, { className: 'normal-text large-bold', children: translate("pages.subject.ViewSubject") }) }), _jsxs(Grid, { spacing: 4, display: 'flex', justifyContent: "flex-end", children: [_jsx(Button, { color: "primary", onClick: function () {
                            setDialogOpened(true);
                        }, className: 'normal-text large-bold', sx: { textTransform: 'none' }, children: translate("dialog.Edit") }), _jsx(Button, { color: "primary", onClick: function () {
                            setSelectedRow(false);
                            navigate('/admin/subjects');
                            if (closeLeftPanel) {
                                closeLeftPanel();
                            }
                        }, className: 'normal-text large-bold', sx: { textTransform: 'none', fontSize: '1.2rem' }, children: translate("common.close") })] })] }));
};
export default SelectedRowLeftColumn;
