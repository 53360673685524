import newLeftEye from '../../../assets/dummyImages/L eye 2.jpg';
import newRightEye from '../../../assets/dummyImages/R eye 1.jpg';
var dummyData = {
    gradableFP: {
        left: [
            { name: "", image: "xxx", comment: "test left" },
            { name: "", image: "xxx", comment: "test left 2" },
        ],
        right: [
            { name: "", image: "xxx", comment: "" }
        ],
    },
    ungradableFP: [
        { image: "", comment: "failed 1 test" },
        { image: "", comment: "" },
    ]
};
var FPAIdummyData = {
    "subjectInfos": {
        "dialogType": "Edit",
        "submit": null,
        "id": "3e5530f4-b57e-4bfa-9920-eec2149e42b1",
        "hkid": "Z123456(1)",
        "lastname": "陳",
        "firstname": "大明",
        "gender": "M",
        "educationLevel": "10-12 yrs",
        "dateOfBirth": "2024-08-21T17:58:53.000Z"
    },
    "examsInfos": {
        "id": "3e5530f4-b57e-4bfa-9920-eec2fffb1",
        "examDate": "2024-08-21T17:58:53.000Z"
    },
    "analysisData": {
        "average_of_fold": 0.9970219379999999,
        "avg_score": 0.2978062000000059,
        "confidence_level": "≈90%",
        "folds": {
            "fold1": 0.99701154,
            "fold2": 0.99987555,
            "fold3": 0.9997745,
            "fold4": 0.9884493,
            "fold5": 0.9999988
        },
        "folds_pred": {
            "fold1_pred": 0,
            "fold2_pred": 0,
            "fold3_pred": 0,
            "fold4_pred": 0,
            "fold5_pred": 0
        },
        "mode": "BE",
        "reverse": 0.002978062000000059,
        "risk_of_AD": "Low",
        "score1": 16.1,
        "score2": 50.2,
        "sum_of_folds_pred": 0,
        "messages": [
            "1. Your brain mimics those with normal cognition. You should continue a brain-healthy lifestyle and have yearly brain health assessments.",
        ]
    },
    "examData": {
        "FP": {
            "gradable": {
                "maculaCentered": {
                    "left": {
                        "comment": null,
                        "img": newLeftEye
                    },
                    "right": {
                        "comment": null,
                        "img": newRightEye
                    }
                },
                "notMaculaCentered": {
                    "left": {
                        "comment": null,
                        "img": newLeftEye
                    },
                    "right": {
                        "comment": null,
                        "img": newRightEye
                    }
                }
            }
        },
        "examId": "rrr 8eb84404-f7bb-406f-a765-9553cfbe1c2e",
        "subjectId": "d439de2d-20fc-48c6-8101-1c0402a820cb"
    },
    "messages": [],
    "result": true,
};
export default dummyData;
export { FPAIdummyData };
