var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import { PaymentElement, LinkAuthenticationElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { settingsStyles as styles } from '../styles';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import TextField from "@mui/material/TextField";
import { Formik } from 'formik';
import { Grid, Button, Dialog, Zoom, } from '@mui/material';
import StripeInput from "./StripeInput";
import { dialog } from '../../../utils/componentWizard';
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
export var StripeTextField = function (props) {
    var helperText = props.helperText, InputLabelProps = props.InputLabelProps, _a = props.InputProps, InputProps = _a === void 0 ? {} : _a, inputProps = props.inputProps, error = props.error, labelErrorMessage = props.labelErrorMessage, stripeElement = props.stripeElement, other = __rest(props, ["helperText", "InputLabelProps", "InputProps", "inputProps", "error", "labelErrorMessage", "stripeElement"]);
    return (_jsx(TextField, __assign({ fullWidth: true, InputLabelProps: __assign(__assign({}, InputLabelProps), { shrink: true }), error: error, InputProps: __assign(__assign({}, InputProps), { inputProps: __assign(__assign(__assign({}, inputProps), InputProps.inputProps), { component: stripeElement }), inputComponent: StripeInput }), helperText: error ? labelErrorMessage : helperText }, other)));
};
function CheckoutForm() {
    var _this = this;
    var stripe = useStripe();
    var elements = useElements();
    var _a = useState(null), message = _a[0], setMessage = _a[1];
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!stripe || !elements) {
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    return [4 /*yield*/, stripe.confirmPayment({
                            elements: elements,
                            confirmParams: {
                                // Make sure to change this to your payment completion page
                                return_url: "".concat(window.location.origin, "/completion"),
                            },
                        })];
                case 1:
                    error = (_a.sent()).error;
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Otherwise, your customer will be redirected to
                    // your `return_url`. For some payment methods like iDEAL, your customer will
                    // be redirected to an intermediate site first to authorize the payment, then
                    // redirected to the `return_url`.
                    if (error.type === "card_error" || error.type === "validation_error") {
                        setMessage(error.message);
                    }
                    else {
                        setMessage("An unexpected error occured.");
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsxs("form", { id: "payment-form", onSubmit: handleSubmit, children: [_jsx("div", { children: "hier" }), _jsx(LinkAuthenticationElement, { id: "link-authentication-element" }), _jsx(PaymentElement, { id: "payment-element" }), _jsx("button", { disabled: isLoading || !stripe || !elements, id: "submit", children: _jsx("span", { id: "button-text", children: isLoading ? _jsx("div", { className: "spinner", id: "spinner" }) : "Pay now" }) }), message && _jsx("div", { id: "payment-message", children: message })] }) }));
}
function StripeTextFieldName() {
    return (_jsx(TextField, { label: "Card holder name", fullWidth: true, required: true, validate: function (value) {
            if (!(value)) {
                return 'Invalid name';
            }
        } }));
}
function StripeTextFieldNumber(props) {
    return (_jsx(StripeTextField, __assign({ label: "Credit Card Number", stripeElement: CardNumberElement }, props)));
}
function StripeTextFieldExpiry(props) {
    return (_jsx(StripeTextField, __assign({ label: "Expiring Date (MM/YY)", stripeElement: CardExpiryElement }, props)));
}
export function StripeTextFieldCVC(props) {
    return (_jsx(StripeTextField, __assign({ label: "CVC Code", stripeElement: CardCvcElement }, props)));
}
function AddCard(props) {
    var _this = this;
    var stripePromise = props.stripePromise, handleSubmit = props.handleSubmit, opened = props.opened, closeDialog = props.closeDialog, data = props.data, translate = props.translate;
    var _a = useState(''), clientSecret = _a[0], setClientSecret = _a[1];
    var _b = React.useState({
        cardNameComplete: false,
        cardNumberComplete: false,
        expiredComplete: false,
        cvcComplete: false,
        cardNameError: null,
        cardNumberError: null,
        expiredError: null,
        cvcError: null
    }), state = _b[0], setState = _b[1];
    var onElementChange = function (field, errorField) { return function (_a) {
        var _b;
        var complete = _a.complete, _c = _a.error, error = _c === void 0 ? { message: null } : _c;
        setState(__assign(__assign({}, state), (_b = {}, _b[field] = complete, _b[errorField] = error.message, _b)));
    }; };
    var cardNumberError = state.cardNumberError, expiredError = state.expiredError, cvcError = state.cvcError, cardNameError = state.cardNameError;
    return (_jsx(_Fragment, { children: _jsxs(Dialog, { open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, sx: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                '& .MuiDialog-paper': {
                    borderRadius: '30px',
                    backgroundColor: "#F5F5F5",
                    padding: "0.5em"
                },
            }, children: [_jsx(dialog.header, { title: "".concat(translate("pages.payment.fields.addNew")), handleClose: closeDialog }), _jsx(Formik, { enableReinitialize: true, initialValues: data, onSubmit: function (values) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            try {
                                if (valuesChecking(values)) {
                                    reloadRecord(__assign(__assign({}, values), { dateOfBirth: dateOfBirth }), data === null || data === void 0 ? void 0 : data.dialogType);
                                    closeDialog();
                                }
                                else {
                                    notify('Please fill in all information.');
                                }
                            }
                            catch (err) {
                                console.log('==err', err);
                            }
                            return [2 /*return*/];
                        });
                    }); }, children: _jsx("form", { noValidate: true, onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, backgroundColor: "", children: _jsx(StripeTextFieldName, { error: Boolean(cardNameError), labelErrorMessage: cardNameError, onChange: onElementChange("cardNameComplete", "cardNameError") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(StripeTextFieldNumber, { error: Boolean(cardNumberError), labelErrorMessage: cardNumberError, onChange: onElementChange("cardNumberComplete", "cardNumberError") }) }), _jsx(Grid, { item: true, xs: 8, children: _jsx(StripeTextFieldCVC, { error: Boolean(cvcError), labelErrorMessage: cvcError, onChange: onElementChange("cvcComplete", "cvcError") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(StripeTextFieldExpiry, { error: Boolean(expiredError), labelErrorMessage: expiredError, onChange: onElementChange("expiredComplete", "expiredError") }) }), _jsx(Button, { variant: "contained", type: "submit", color: "primary", style: styles.creditCard.payBtn, 
                                    // disabled={loading}
                                    fullWidth: true, children: "Add card" }), _jsx(Button, { variant: "contained", type: "submit", color: "primary", style: styles.creditCard.cancelBtn, 
                                    // disabled={loading}
                                    fullWidth: true, children: "Cancel" }), _jsx("pre", { children: JSON.stringify(state, null, 2) })] }) }) })] }) }));
}
export default AddCard;
