import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import { filter } from '../../utils/componentWizard';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, setClickedPanelComp = _a.setClickedPanelComp, backgroundColor = _a.backgroundColor;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: {
            cursor: "pointer",
            backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white"
        }, onClick: function () { }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var LanguageComp = function (_a) {
    var params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    return (_jsx(_Fragment, { children: filters && _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "spcae-between", children: [_jsx(Grid, { item: true, md: 9, xs: 8, children: _jsx(Typography, { sx: { marginTop: '1.1rem', fontSize: '1.1rem', fontWeight: 'bold' }, children: translate("pages.settings.fields.language.lan_caption") }) }), _jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", style: { backgroundColor: "" }, children: _jsx(filter.dropDownListSetting, { notIncludeAllSelection: true, filters: filters, setFilters: setFilters, label: "", name: "language", minWidth: 260, options: params }) })] }) }));
};
export default LanguageComp;
