import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslate } from 'react-admin';
import { Typography, Grid, TextField, IconButton } from '@mui/material';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import DeleteIcon from '@mui/icons-material/Delete';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { imgSrcPathHelper, dragImgNameHelper } from '../../utils/helper';
import { Draggable } from './dragAndDrop/Draggable';
var styles = {
    documentBlock: {
        width: 160,
        height: 200,
        margin: "20px auto",
        marginTop: 8,
        overflow: "hidden"
    },
    controls: {
        maxWidth: 160,
        margin: "0 auto",
        marginTop: 8,
    },
    container: {
        width: "100%",
        height: "65vh",
        // backgroundColor: "#fafbfc"
        backgroundColor: "white"
    },
    gradableTitle: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        backgroundColor: "#e0eefb"
        // backgroundColor: "#e0eefb"
    },
    expandIconStyle: {
        position: "absolute",
        right: 0,
        top: 0,
        color: "white"
    },
};
var GradableImageGrid = function (_a) {
    var image = _a.image, comment = _a.comment, eyeType = _a.eyeType, handleOpenImage = _a.handleOpenImage, handelImageGrid = _a.handelImageGrid, idx = _a.idx, id = _a.id, isDragging = _a.isDragging, imgName = _a.imgName;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [isDragging &&
                _jsx(Draggable, { id: id, children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 1, margin: 1, style: { backgroundColor: "#FAFAFA", maxHeight: "10rem" }, children: [_jsxs(Grid, { item: true, margin: 1, style: { backgroundColor: "", }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "end", children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handelImageGrid(idx, 'delete'); }, children: _jsx(DeleteIcon, { fontSize: "small" }) })] }), _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", position: "relative", style: { backgroundColor: "" }, children: _jsx("img", { src: imgSrcPathHelper(image), alt: "Gradable image", width: "80", height: "80" }) })] }), _jsxs(Grid, { item: true, paddingX: 1, children: [_jsx(Typography, { variant: "body1", children: translate('fp.comment') }), _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                            handelImageGrid(idx, 'cmt', e.target.value);
                                        } }), _jsx(Grid, { item: true, children: dragImgNameHelper(imgName) })] })] }) }), !isDragging &&
                _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 1, margin: 1, style: { backgroundColor: "#FAFAFA", maxHeight: "10rem" }, children: [_jsxs(Grid, { item: true, margin: 1, style: { backgroundColor: "", }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "end", children: [_jsx(Draggable, { id: id, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handelImageGrid(idx, 'delete'); }, children: _jsx(DeleteIcon, { fontSize: "small" }) })] }), _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", position: "relative", style: { backgroundColor: "" }, children: [_jsx("img", { src: imgSrcPathHelper(image), alt: translate('fp.gradableImage'), width: "80", height: "80" }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", style: styles.expandIconStyle, onClick: function () { handleOpenImage(imgSrcPathHelper(image)); }, children: _jsx(AspectRatioIcon, { fontSize: "small" }) })] })] }), _jsxs(Grid, { item: true, paddingX: 1, children: [_jsx(Typography, { variant: "body1", children: translate('fp.comment') }), _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                        handelImageGrid(idx, 'cmt', e.target.value);
                                    } }), _jsx(Grid, { item: true, children: dragImgNameHelper(imgName) })] })] })] }));
};
var UngradableImageGrid = function (_a) {
    var image = _a.image, comment = _a.comment, idx = _a.idx, id = _a.id, handelImageGrid = _a.handelImageGrid, isDragging = _a.isDragging, imgName = _a.imgName;
    var translate = useTranslate();
    return (_jsxs(_Fragment, { children: [isDragging &&
                _jsx(Draggable, { id: id, children: _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 1, margin: 1, style: { backgroundColor: "#FAFAFA", maxHeight: "10rem" }, children: [_jsxs(Grid, { item: true, margin: 1, style: { backgroundColor: "", }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "end", children: [_jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handelImageGrid(idx, 'delete'); }, children: _jsx(DeleteIcon, { fontSize: "small" }) })] }), _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", position: "relative", style: { backgroundColor: "" }, children: _jsx("img", { src: imgSrcPathHelper(image), alt: "Ungradable image", width: "80", height: "80" }) })] }), _jsxs(Grid, { item: true, paddingX: 1, children: [_jsx(Typography, { variant: "body1", children: translate('fp.comment') }), _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                            handelImageGrid(idx, 'cmt', e.target.value);
                                        } }), _jsx(Grid, { item: true, children: dragImgNameHelper(imgName) })] })] }) }), !isDragging &&
                _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", padding: 1, margin: 1, style: { backgroundColor: "#FAFAFA", maxHeight: "10rem" }, children: [_jsxs(Grid, { item: true, margin: 1, style: { backgroundColor: "", }, children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "end", children: [_jsx(Draggable, { id: id, children: _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", children: _jsx(ControlCameraIcon, { fontSize: "small" }) }) }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: function () { handelImageGrid(idx, 'delete'); }, children: _jsx(DeleteIcon, { fontSize: "small" }) })] }), _jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", position: "relative", style: { backgroundColor: "" }, children: _jsx("img", { src: imgSrcPathHelper(image), alt: translate('fp.ungradableImage'), width: "80", height: "80" }) })] }), _jsxs(Grid, { item: true, paddingX: 1, children: [_jsx(Typography, { variant: "body1", children: translate('fp.comment') }), _jsx(TextField, { id: "outlined-basic", multiline: true, rows: 3, label: "", variant: "outlined", value: comment !== null && comment !== void 0 ? comment : "", onChange: function (e) {
                                        handelImageGrid(idx, 'cmt', e.target.value);
                                    } }), _jsx(Grid, { item: true, children: dragImgNameHelper(imgName) })] })] })] }));
};
export { GradableImageGrid, UngradableImageGrid };
