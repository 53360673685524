var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import englishMessages from "ra-language-english";
var customEnglishMessages = __assign(__assign({}, englishMessages), { login: {
        signIn: "Sign In",
        register: "Register",
        resend: "Resend",
        username: "Username",
        email: "Email",
        password: "Password",
        AI_Screening_Platform: "AI Screening Platform",
        forgetPW: {
            caption: {
                willSendEmail: "An email will be sent to your inbox to initiate the password reset process.",
                sentEmail: "An email has been sent to your inbox. Please check your email to continue the password reset process.",
                notReceiveEmail: "Not receiving the email?",
            },
        },
        SignUpNow: "Sign up now",
        ForgetYourPassword: "Forget your password?",
        ForgetPassword: "Forget Password",
        YourPassword: "Your Password",
        YourUsername: "Your Username",
        ConfirmPassword: "Confirm Password",
        BackToSignIn: "Back to Sign In",
    }, breadcrumb: {
        main: "Main",
        userManagement: "User Management",
    }, fp: {
        left_eye: "Left Eye",
        right_eye: "Right Eye",
        macula_centered: "Macula-centered",
        disc_centered: "Disc-centered",
        gradableFp: "Gradable FP",
        ungradableFp: "Ungradable FP",
        comment: "Comment (if any)",
        ungradableImage: "Ungradable image",
        gradableImage: "Gradable image",
        fundusPhotos: "Fundus Photos",
        pleaseUploadTheFundusPhotos: "Please upload the fundus photos.",
        dragImagesHere: "Drag images here (Max 10)",
        dragImagesHere2: "Drag images here",
        supportedFileType: "Supported file type: .jpg, .png",
        fileSize: "File size: up to 10 MB",
        quotaLeft: "Quota left",
    }, components: {
        ai_results: "AI Results",
        data_import: "Data Import",
    }, tooltip: {
        viewSubject: "View Subject",
        viewExam: "View Exam",
        viewUser: "View User",
        editSubject: "Edit Subject",
        editExam: "Edit Exam",
        editUser: "Edit User",
        editAccessRight: "Edit Access Right",
        editGroup: "Edit Group",
        deleteSubject: "Delete Subject",
        deleteExam: "Delete Exam",
        deleteUser: "Delete User",
        deleteOrg: "Delete Organization",
        deleteGroup: "Delete Group",
        importData: "Import Data",
        viewAndEditOrg: "View and Edit Organization",
        checkOutUser: "Checkout User",
        disableUser: "Disable User",
    }, comfirmDialog: {
        subject: "Are you sure you want to delete this subject?",
        exam: "Are you sure you want to delete this exam?",
        user: "Are you sure you want to delete this user?",
        organization: "Are you sure you want to delete this organization?",
        group: "Are you sure you want to delete this group?",
        disableUser: "Are you sure you want to disable this user?",
    }, pos: {
        search: "Search",
        configuration: "Configuration",
        language: "Language",
        theme: {
            name: "Theme",
            light: "Light",
            dark: "Dark",
        },
        menu: {
            files: "Files",
            main: "Main",
            userManagement: "User Management",
        },
    }, common: {
        search: "Search",
        save: "Save",
        analyze: "Analyze",
        close: "Close",
        confirm: "Confirm",
        back: "Back",
        selectAll: "Select All",
        unselectAll: "Unselect All",
    }, dialog: {
        export: "Export",
        exportSubjects: "Export Subjects",
        submit: "Submit",
        submit_and_import: "Submit & Import Data",
        Edit: "Edit",
        Create: "Create",
    }, pages: {
        common: {
            selection: {
                select_all: "Select all",
                reset: "Reset",
                select: "Select",
            },
        },
        subject: {
            name: "Subject",
            title: "Subject",
            ViewSubject: "View Subject",
            fields: {
                info: "Subject Information",
                hkid: "ID",
                id: "Subject ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                dateOfBirthFrom: "Date of birth - From",
                dateOfBirthTo: "Date of birth - To",
                educationLevel: "Education level",
                actions: "Actions",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            addNew: "Add new subject",
        },
        exam: {
            name: "Exam",
            title: "Exam",
            allExamDate: "All Exam Date",
            fields: {
                exam_date: "Exam date",
                ai_model: "AI model",
                eye: "Eye",
                risk_of_ad: "Outcome",
                confidence_level: "Confidence level",
                status: "Status",
                analysisDate: "Analysis Date",
                actions: "Actions",
                hkid: "HK ID",
                subject_id: "Subject ID",
                id: "Exam ID",
                firstname: "First name",
                lastname: "Last name",
                gender: "Gender",
                dateOfBirth: "Date of birth",
                educationLevel: "Education level",
                examFromDate: "Exam - From",
                examToDate: "Exam - To",
                analyzeFromDate: "Analyze - From",
                analyzeToDate: "Analyze - To",
            },
            dialog: {
                edit_exam: "Edit Exam ",
                add_new_exam: "Add New Exam",
            },
            fp: {
                analyzeCases: {
                    cases: "Analyzable cases:",
                    both: "1. Both eyes: Macula-centered and Disc-centered left & right eye images ",
                    leftOnly: "2. Left eyes only:  Macula-centered and Disc-centered left eye images",
                    rightOnly: "3. Right eyes only:  Macula-centered and Disc-centered right eye images",
                },
            },
        },
        dataExport: {
            name: "Data Export",
            criteria: "Data Export Criteria",
            fields: {
                subject_information: "Subject Information",
                images: "Images",
                fps: "FPs",
                fp: "FP",
                oct_reports: "Oct reports",
                retinAD_reports: "RetinAD reports",
                oct: "OCT",
                fp_oct: "FP + OCT",
                retinAD_probability_scores: "RetinAD probability scores",
                internal_use: "Internal use only",
                questionnaire_results: "Questionnaire results",
                examDate: "Exam",
                analyzeDate: "Analyze",
                dateFrom: "From",
                dateTo: "To",
            },
            dialog: {
                subjects_selected: "No. of subject(s) selected: ",
            },
        },
        settings: {
            name: "Settings",
            leftCol: {
                profile: "Profile",
                language: "Language",
                security: "Security",
                membership: "Membership",
                payment: "Payment",
            },
            fields: {
                profile: {
                    headers: {
                        info: "General Information",
                        set_new_pw: "Set New Password",
                        access: "View Your Access Right",
                        accessRight: "Access Right",
                    },
                    info: {
                        id: "ID",
                        user_id: "User ID",
                        user_group: "User group",
                        username: "Username",
                        name: "Name",
                        firstname: "First name",
                        lastname: "Last name",
                        gender: "Gender",
                        email: "Email",
                    },
                    password: {
                        current: "Current PW",
                        new: "New Password",
                        confirm_new: "Confirm New PW",
                    },
                    access: {
                        dashboard: "Dashboard",
                        user_group: "User Group",
                        subject: "Subject",
                        data_export: "Data Export",
                    },
                    crud: {
                        c: "Add",
                        r: "View",
                        u: "Edit",
                        d: "Delete",
                    },
                },
                language: {
                    lan_caption: "Change language display setting",
                },
                security: {
                    header2fa: "Two Factor Authentication",
                    caption2fa: "Adds an extra security step to login, requiring a time-limited 6-digit code sent to your device to verify it's you.",
                },
            },
        },
        membership: {
            btn: {
                cancel: "Cancel",
                renew: "Renew",
                subsMonth: "Subscribe (Month)",
                subsYear: "Subscribe (Year)",
            },
            fields: {
                plans: {
                    genLeft: " generations left",
                    currentPlan: "Current Plan",
                    advanced: "Advanced",
                    monthly: "Monthly",
                    yearly: "Yearly",
                    caption: "* Unless you choose to cancel your subscription, your membership will automatically renew upon expiration.",
                },
                headers: {
                    current: "Current Plan",
                    basic: "Basic",
                    standard: "Standard",
                    advanced: "Advanced",
                    premium: "Premium",
                },
                details: {
                    subsPreTermsAndCond: "By subscribing, you agree to our ",
                    termsAndCond: " Terms & Conditions.",
                    unlimited: "Unlimited",
                    genMonthly: "AI report generations per month",
                    month: "Month",
                    year: "Year",
                    save2monthfee: "saving 2 months of fees",
                },
            },
        },
        payment: {
            fields: {
                addNew: "Add new card",
            }
        },
        userList: {
            name: "User",
            listName: "User List",
            ViewUser: "View User",
            fields: {
                id: "User ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
                pw: "Password",
                confirmpw: "Confirm PW",
            },
            addNew: "Add new user",
            info: "User Information",
        },
        organizations: {
            name: "Organizations",
            editComp: {
                general: "General",
                groups: "Groups",
                users: "Users",
                group: "Group",
            },
            fields: {
                orgId: "Org ID",
                id: "Group ID",
                userGroup: "User group",
                username: "Username",
                organization: "Organization",
                orgName: "Org name",
                groupNum: "Number of Groups",
                userNum: "Number of Users",
                groupName: "Group name",
                email: "Email",
                status: "Status",
                createdAt: "Created at",
                updatedAt: "Updated at",
            },
            availableUsersInOrg: "Available Users in Org ",
            noUsersInOrganization: "No users in organization",
            userIn: "Users in ",
            selected: "selected",
            addNew: "Add new organization",
            addNewGroup: "Add new group",
            addNewUser: "Create New User For This Organization",
            info: "User Information",
            groupList: {
                fields: {
                    name: "Group name",
                },
            },
            userList: {
                group: "Group",
                accessRight: "Access Right ",
                filters: { userId: "User ID" },
                fields: {
                    name: {
                        dashboard: "Dashboard",
                        user: "User",
                        organization: "Organization",
                        subjects: "Subjects",
                        dataExport: "Data Export",
                    },
                    dashboard: {
                        gen: "View General Analysis",
                        sales: "View Sales Analysis",
                    },
                    user: {
                        viewAll: "View all users",
                        viewOrg: "View organization users",
                        create: "Create new users",
                        edit: "Edit all users",
                        editOrg: "Edit organization users",
                        disable: "Disable users",
                    },
                    organization: {
                        viewAll: "View all organizations",
                        viewOwn: "View your organization",
                        create: "Create new organization",
                        disable: "Disable all organizations",
                        disableOwnOrg: "Disable your organization",
                        edit: "Edit all organizations",
                        editOwn: "Edit your organization",
                        editGroup: "Edit all organization groups",
                        editOwnGroup: "Edit your groups",
                        addGroup: "Add new groups",
                        disableGroup: "Disable all groups",
                        disableOwnGroup: "Disable your groups",
                        disableOrg: "Disable organization users",
                        createNewOrg: "Create new organization users",
                    },
                    subjects: {
                        view: "View all subjects",
                        viewOrg: "View organization subjects",
                        create: "Create new subjects",
                        edit: "Edit subjects",
                        delete: "Delete subjects",
                    },
                    dataExport: {
                        all: "Export data of all subjects",
                        org: "Export data of organization subjects",
                        retinAD: "Export RetinAD probability scores",
                    },
                },
            },
        },
        dashboard: {
            name: "Dashboard",
            amount: "1 review |||| %{smart_count} reviews",
            relative_to_poster: "Review on poster",
            detail: "Review detail",
            fields: {
                customer_id: "Customer",
                command_id: "Order",
                product_id: "Product",
                date_gte: "Posted since",
                date_lte: "Posted before",
                date: "Date",
                comment: "Comment",
                rating: "Rating",
            },
            action: {
                accept: "Accept",
                reject: "Reject",
            },
            notification: {
                approved_success: "Review approved",
                approved_error: "Error: Review not approved",
                rejected_success: "Review rejected",
                rejected_error: "Error: Review not rejected",
                noData: "No data",
            },
            title: {
                allOrganizations: "All Organizations",
                yourOrganization: "Your Organization",
                revenueReport: "Revenue Report",
                totalSubjects: "Total Subjects",
                totalExams: "Total Exams",
                generatedReports: "Generated Reports",
                advancedYearly: "Advanced - Yearly",
                unfinishExams: "Unfinished Exams",
                finishedExams: "Finished Exams",
                cbu: "Created by you",
                iuo: "In your organization",
                until20241231: "Until 2024/12/31",
                barChart: "Total Subjects and High-Risk Subjects by Age Group in all organization",
                redlist: "Recent High Risk Subjects in all organization",
                pie1: "Confidence level distribution in finished exams - Total:",
                pie2: "Eye distribution in finished exams - Total:",
                pie3: "AI model distribution in finished exams - Total:",
                pie4: "Gender distribution - Total:",
                pie5: "Education level distribution - Total:",
                pie6: "Age Group distribution - Total:",
            },
        },
    } });
export default customEnglishMessages;
