var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Card, CardActions, CircularProgress, Grid, Typography, } from '@mui/material';
import { required, TextInput, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
// import LoginLogo from '../assets/logos/login logo.png'
import ICSLogo from '../../assets/logos/ics logo.png';
import '../../styles/globalStyle.css';
var SignIn = function (_a) {
    var loading = _a.loading, signInInputs = _a.signInInputs, setSignInInputs = _a.setSignInInputs, setCurComp = _a.setCurComp;
    var _b = useState(false), showPassword = _b[0], setShowPassword = _b[1];
    var translate = useTranslate();
    console.log(showPassword);
    return (_jsx(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: '#ebeff2',
            backgroundSize: 'cover',
        }, children: _jsxs(Card, { sx: {
                minWidth: 300,
                maxWidth: 550,
                marginTop: '6em',
                borderRadius: "40px",
                boxShadow: '20px 20px 25px rgba(77, 106, 149, 0.5)', // Light blue color
            }, children: [_jsx(Box, { sx: {
                        margin: '1em',
                        display: 'flex',
                        justifyContent: 'center',
                    }, children: _jsx("img", { src: ICSLogo, alt: "logo", height: 135, style: {
                            transition: 'transform 0.8s ease-in-out',
                        }, onMouseEnter: function (e) {
                            e.currentTarget.style.transform = 'scale(2)';
                        }, onMouseLeave: function (e) {
                            e.currentTarget.style.transform = 'scale(1)';
                        } }) }), _jsx(Box, { sx: {
                        marginTop: '1em',
                        display: 'flex',
                        flexWrap: "wrap",
                        justifyContent: 'center',
                        color: function (theme) { return theme.palette.grey[500]; },
                    }, children: _jsx(Grid, { container: true, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", style: { backgroundColor: "", width: "30rem" }, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(Typography, { className: 'big-text large-bold', textAlign: "center", color: "black", children: translate('login.AI_Screening_Platform') }), _jsx(Typography, { className: 'big-text no-bold', textAlign: "center", color: "black", children: translate('login.signIn') })] }) }) }), _jsxs(Box, { sx: { padding: '0 1em 1em 1em' }, children: [_jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { variant: "body1", className: 'small-text large-bold', color: "#4d6a95", sx: { margin: '0px 0px 0px 10px' }, children: translate('login.username') }), _jsx(TextInput, { autoFocus: true, variant: 'outlined', source: "username", label: false, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { username: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, placeholder: translate('login.YourUsername'), sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            height: "65px",
                                        },
                                    } })] }), _jsxs(Box, { sx: { marginTop: '1em' }, children: [_jsx(Typography, { className: 'small-text large-bold', color: "#4d6a95", sx: { margin: '0px 0px 0px 10px' }, children: translate('login.password') }), _jsx(TextInput, { variant: 'outlined', source: showPassword ? 'text' : 'password', label: false, onChange: function (e) {
                                        setSignInInputs(__assign(__assign({}, signInInputs), { password: e.target.value }));
                                    }, disabled: loading, validate: required(), fullWidth: true, type: "password", placeholder: translate('login.YourPassword'), 
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end">
                                    //             <IconButton
                                    //                 aria-label="toggle password visibility"
                                    //                 onClick={() => setShowPassword(!showPassword)}
                                    //             >
                                    //                 {showPassword ? <Visibility /> : <VisibilityOff />}
                                    //             </IconButton>
                                    //         </InputAdornment>
                                    //     ),
                                    // }}
                                    sx: {
                                        '& .MuiInputBase-root': {
                                            borderRadius: '10px',
                                            height: "65px",
                                        },
                                    } })] })] }), _jsx(CardActions, { sx: { padding: '0 1em 1em 1em' }, children: _jsxs(Button, { className: 'normal-text', variant: "contained", type: "submit", color: "primary", disabled: loading, fullWidth: true, sx: {
                            backgroundColor: "#395d7d",
                            borderRadius: "10px",
                            position: "relative",
                            overflow: "hidden",
                            transition: "all 0.3s ease-in-out",
                            "&:before": {
                                content: '""',
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "0%",
                                height: "0%",
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                borderRadius: "50%",
                                transition: "all 0.5s ease-out",
                            },
                            "&:hover": {
                                transform: "translateY(-5px)",
                                boxShadow: "0 10px 20px rgba(77, 106, 149, 0.4)",
                                "&:before": {
                                    width: "300%",
                                    height: "300%",
                                    top: "-150%",
                                    left: "-150%",
                                },
                            },
                            "&:active": {
                                transform: "translateY(2px)",
                            }
                        }, children: [console.log(loading, '==loading'), loading && (_jsx(CircularProgress, { size: 25, thickness: 2 })), translate('login.signIn')] }) }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", padding: 2, children: [_jsx(Typography, { className: 'small-text large-bold', textAlign: "center", color: "#395d7d", style: { cursor: "pointer" }, onClick: function () { setCurComp("register"); }, sx: { margin: '0px 0px 70px 25px' }, children: translate('login.SignUpNow') }), _jsx(Typography, { className: 'small-text large-bold', textAlign: "center", color: "#395d7d", style: { cursor: "pointer" }, onClick: function () { setCurComp("forgetPW"); }, sx: { margin: '0px 25px 70px 0px' }, children: translate('login.ForgetYourPassword') })] })] }) }));
};
export default SignIn;
