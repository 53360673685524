var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import * as React from 'react';
import { useState } from "react";
import { Grid, Typography, Dialog, DialogActions, DialogContent, Zoom } from '@mui/material';
import { defaultDateOfBirth, } from '../utils/datePickerTimeFormat';
import { Formik } from 'formik';
// import { useDispatch } from 'react-redux';
// import API from '../../../api';
// import * as actionTypes from '../../../store/actions';
import { dialog } from '../utils/componentWizard';
import { Form as RAForm, DateInput as RADateInput, minValue as RAminValue, useNotify } from 'react-admin';
import { EducationLevelMuiChoices, GenderMuiChoices } from './Params'; //[back] [now] see if still works
var classes = {
    noMarginDialog: {
        '&>div:nth-child(3)': {
            '&>div': {
                margin: 0,
            },
        },
    }
};
var Transition = React.forwardRef(function Transition(props, ref) {
    return _jsx(Zoom, __assign({ ref: ref }, props));
});
var EditDialog = function (_a) {
    var _b;
    var opened = _a.opened, data = _a.data, 
    // openDialog,
    closeDialog = _a.closeDialog, reloadRecord = _a.reloadRecord, translate = _a.translate, params = _a.params;
    var notify = useNotify();
    var _c = useState(defaultDateOfBirth()), dateOfBirth = _c[0], seDateOfBirth = _c[1];
    var valuesChecking = function (givenValues) {
        var hasAllVals = givenValues.hkid
            && givenValues.lastname
            && givenValues.firstname
            && givenValues.gender
            && givenValues.educationLevel;
        return hasAllVals;
    };
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, { open: opened, TransitionComponent: Transition, onClose: closeDialog, className: classes.noMarginDialog, keepMounted: true, sx: {
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                '& .MuiDialog-paper': {
                    borderRadius: '30px',
                    backgroundColor: "#F5F5F5",
                    padding: "0.5em"
                },
            }, children: [_jsx(dialog.header, { title: "".concat(translate("dialog.".concat(data.dialogType)), " ").concat(translate("pages.subject.name"), " ").concat((_b = data === null || data === void 0 ? void 0 : data.hkid) !== null && _b !== void 0 ? _b : ""), handleClose: closeDialog }), _jsx(Formik, { enableReinitialize: true, initialValues: data, onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            try {
                                if (valuesChecking(values)) {
                                    reloadRecord(__assign(__assign({}, values), { dateOfBirth: dateOfBirth }), data === null || data === void 0 ? void 0 : data.dialogType);
                                    closeDialog();
                                }
                                else {
                                    notify('Please fill in all information.');
                                }
                            }
                            catch (err) {
                                console.log('==err', err);
                            }
                            return [2 /*return*/];
                        });
                    }); }, children: function (props) {
                        var values = props.values, handleSubmit = props.handleSubmit;
                        return (_jsxs("form", { noValidate: true, onSubmit: handleSubmit, children: [_jsxs(DialogContent, { children: [_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", sx: {
                                                boxShadow: '0 0 5px gray',
                                                borderRadius: '20px',
                                                marginBottom: "0.7em",
                                                padding: "0.5em",
                                                backgroundColor: "white"
                                            }, children: [data.dialogType === "Edit" &&
                                                    _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsx(Typography, { variant: "body1", padding: 2, sx: {
                                                                        fontWeight: 900,
                                                                        fontSize: "0.9em",
                                                                        opacity: 0.4,
                                                                        textAlign: 'right',
                                                                    }, children: translate("pages.subject.fields.id") }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "id", width: 200, required: true, disabled: true }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.subject.fields.hkid"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "hkid", width: 140, 
                                                                /*  required
                                                                  validate={(value) => {
                                                                      const hkidRegex = /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/;
                                                                     if (!hkidRegex.test(value)) {
                                                                         return 'Invalid Hong Kong ID format';
                                                                  }
                                                                  }}
                                                                 helperText={props.errors.hkid}
                                                                 error={props.touched.hkid && Boolean(props.errors.hkid)} */
                                                                FormHelperTextProps: {
                                                                    sx: { fontWeight: 500 }
                                                                } }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.userList.fields.organization"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.dropDownList, { form: props, filters: [], setFilters: function () { }, options: params.organizations, label: "", name: "editOrganizations", width: 140 }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    border: '0px solid',
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.subject.fields.lastname"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "lastname", required: true }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.subject.fields.firstname"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.textField, { form: props, filters: [], setFilters: function () { }, label: "", name: "firstname", width: 140 }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.subject.fields.gender"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.dropDownList, { form: props, filters: [], setFilters: function () { }, options: GenderMuiChoices, label: "", name: "gender", width: 140 }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right'
                                                                }, children: [translate("pages.subject.fields.educationLevel"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(dialog.dropDownList, { form: props, filters: [], setFilters: function () { }, options: EducationLevelMuiChoices, label: "", name: "educationLevel" }) })] }), _jsxs(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: [_jsx(Grid, { xs: 5, children: _jsxs(Typography, { variant: "body1", padding: 2, sx: {
                                                                    fontWeight: 900,
                                                                    fontSize: "0.9em",
                                                                    opacity: 0.4,
                                                                    textAlign: 'right',
                                                                    marginBottom: '25px'
                                                                }, children: [translate("pages.subject.fields.dateOfBirth"), "*"] }) }), _jsx(Grid, { xs: 7, children: _jsx(RAForm, { children: _jsx(RADateInput, { source: "subject_date_of_birth", label: "", validate: RAminValue(new Date('2024-06-06')), onChange: function (e) {
                                                                        seDateOfBirth(e.target.value);
                                                                    }, variant: "outlined", sx: {
                                                                        '& .MuiInputBase-root': {
                                                                            borderRadius: '10px',
                                                                            boxShadow: '0 0 5px gray',
                                                                            width: "10em",
                                                                            overflow: "auto"
                                                                        },
                                                                        '& .MuiFormLabel-root': {},
                                                                    }, defaultValue: dateOfBirth ? new Date(dateOfBirth) : new Date() }) }) })] })] }), _jsx(Grid, { item: true, xs: 12, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", children: _jsx(dialog.submitButton, { isSubmitting: props.isSubmitting, text: translate('dialog.submit') }) })] }), _jsx(DialogActions, {})] }));
                    } })] }) }));
};
export default EditDialog;
