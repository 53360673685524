var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { editComp, } from '../../../utils/componentWizard';
import SearchIcon from '@mui/icons-material/Search';
var Spacer = function () { return _jsx(Box, { mb: 1, children: "\u00A0" }); };
var FilterList = function (_a) {
    var _b;
    var search = _a.search, isLoading = _a.isLoading, filters = _a.filters, setFilters = _a.setFilters, params = _a.params, translate = _a.translate, searchFilterGroup = _a.searchFilterGroup;
    var sessonStorageJSON = (_b = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _b !== void 0 ? _b : {};
    var updatedFilters = __assign(__assign({}, filters), sessonStorageJSON);
    return (_jsxs(Grid, { container: true, spacing: 1, justifyContent: "start", display: "flex", flexWrap: "wrap", backgroundColor: "", children: [_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.organizations.userList.filters.userId"), name: "id", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.userList.fields.username"), name: "username", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.subject.fields.lastname"), name: "Last_name", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.subject.fields.firstname"), name: "First_name", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.userList.fields.email"), name: "email", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(editComp.filterTextField, { filters: filters, setFilters: setFilters, label: translate("pages.subject.fields.gender"), name: "gender", width: "100%" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 3, xl: 2, children: _jsx(Grid, { item: true, alignItems: "end", justifyContent: "end", children: _jsx(Button, { variant: "contained", disabled: isLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, searchFilterGroup()];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        }); }); }, color: "common", startIcon: _jsx(SearchIcon, { sx: {
                                animation: 'pulse 2s infinite ease-in-out',
                                '@keyframes pulse': {
                                    '0%': {
                                        transform: 'scale(1)',
                                    },
                                    '50%': {
                                        transform: 'scale(1.5)',
                                    },
                                    '100%': {
                                        transform: 'scale(1)',
                                    },
                                },
                            } }), sx: {
                            marginTop: "0.4em",
                            height: "3rem",
                            width: "9em",
                            fontWeight: "900",
                            color: "#0d47a1",
                            borderRadius: '20px',
                            boxShadow: '0px 6px 8px gray',
                            opacity: "0.6",
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                backgroundColor: '#1976d2',
                                color: 'white',
                                transform: 'scale(1.05)',
                                boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.3)',
                                opacity: "1",
                            },
                            '&:active': {
                                transform: 'scale(0.95)',
                            },
                            '&:hover .MuiSvgIcon-root': {
                                animation: 'pulseHover 1.5s infinite ease-in-out',
                            },
                            '@keyframes pulseHover': {
                                '0%': {
                                    transform: 'scale(1)',
                                },
                                '50%': {
                                    transform: 'scale(1.5)',
                                },
                                '100%': {
                                    transform: 'scale(1)',
                                },
                            },
                        }, children: isLoading ? _jsx(CircularProgress, { size: 24 }) : translate("pos.search") }) }) })] }));
};
export default FilterList;
