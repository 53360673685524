import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { DatagridConfigurable, DateField, List, TextField, FunctionField, } from 'react-admin';
import { useMediaQuery, Typography, Grid, Button, Checkbox, } from '@mui/material';
import { blueIDHelper } from '../utils/helper';
import { useDispatch } from 'react-redux';
import { subjectIdHelper } from '../utils/helper';
var TableDatagrid = function (_a) {
    var selectedAll = _a.selectedAll, setSelectedAll = _a.setSelectedAll, translate = _a.translate, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, educationLevelArray = _a.educationLevelArray, dataProvider = _a.dataProvider, refresh = _a.refresh, notify = _a.notify, resource = _a.resource, navigate = _a.navigate, handleBulkSelection = _a.handleBulkSelection, previewExport = _a.previewExport, acessControlList = _a.acessControlList, dataGridHeader = _a.dataGridHeader;
    // const { filterValues, setFilters, displayedFilters, sort } = listContext;
    // console.log(sort, '==listContext')
    var isXSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    var dispatch = useDispatch();
    // const controllerProps = useListController();
    return (_jsx(Fragment, { children: _jsx(List, { resource: resource, actions: _jsx(_Fragment, {}), sort: { field: 'id', order: 'DESC' }, pagination: false, children: _jsxs(DatagridConfigurable
            // header={<div></div>}
            , { 
                // header={<div></div>}
                bulkActionButtons: false, isLoading: isLoading, 
                // rowClick={handleRowClick}
                data: data, sx: {
                    '& .MuiTableCell-root': {
                        padding: '1px',
                    },
                }, children: [_jsx(FunctionField, { source: "id", sortable: false, 
                        // fieldProps={{ sortable: false, }}
                        // sx={{ ".MuiTableSortLabel-icon": { opacity: 0 } }}
                        label: "", render: function (record) {
                            return (_jsx(_Fragment, { children: (acessControlList.dataExport["export-all"] || acessControlList.dataExport["export-org"]) &&
                                    _jsx(Grid, { item: true, md: 3, xs: 4, display: "flex", flexWrap: "wrap", justifyContent: "flex-end", children: _jsx(Checkbox, { sx: { backgroundColor: "", transform: 'translatex(2rem)', }, checked: record.selected, onChange: function (e) { handleBulkSelection(false, record, record.selected, e.target.checked); } }) }) }));
                        } }), _jsx(FunctionField, { source: "hkid", label: translate("pages.subject.fields.hkid"), sortable: false, render: function (record) {
                            return (_jsx(Typography, { children: !record.isHeader ? blueIDHelper(record.hkid) : record.hkid }));
                        } }), _jsx(FunctionField, { source: "id", sortable: false, label: translate("pages.subject.fields.id"), render: function (record) {
                            return (_jsx(Typography, { children: subjectIdHelper(record.id) }));
                        } }), _jsx(TextField, { source: "lastname", sortable: false, label: translate("pages.subject.fields.lastname") }), _jsx(TextField, { source: "firstname", sortable: false, label: translate("pages.subject.fields.firstname") }), _jsx(TextField, { source: "gender", sortable: false, label: translate("pages.subject.fields.gender") }), _jsx(DateField, { source: "dateOfBirth", sortable: false, label: translate("pages.subject.fields.dateOfBirth") }), _jsx(FunctionField, { source: "educationLevel", sortable: false, label: translate("pages.subject.fields.educationLevel"), render: function (record) {
                            var bgColor;
                            switch (record.educationLevel) {
                                case educationLevelArray[0]:
                                    bgColor = "#aad5fd";
                                    break;
                                case educationLevelArray[1]:
                                    bgColor = "#6bb0eb";
                                    break;
                                case educationLevelArray[2]:
                                    bgColor = "#3d7ebe";
                                    break;
                                case educationLevelArray[3]:
                                    bgColor = "#0d5da8";
                                    break;
                                case educationLevelArray[4]:
                                    bgColor = "#004587";
                                    break;
                                case educationLevelArray[5]:
                                    bgColor = "#3d648a";
                                    break;
                            }
                            return (_jsx(Button, { style: {
                                    backgroundColor: bgColor,
                                    color: "white",
                                    padding: "5px 5px",
                                    fontSize: "14px",
                                    textTransform: 'none',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'all 0.3s ease',
                                }, sx: {
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                                    },
                                }, children: record.educationLevel }));
                        } })] }) }) }));
};
export default TableDatagrid;
