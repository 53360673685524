var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import AddCard from '../helper/Payment/CreditcardComp';
var stripePromise = loadStripe("pk_test_ju6veMmqd5eDMe1XhQVPyze2");
import { Elements } from "@stripe/react-stripe-js";
var PaymentComp = function (_a) {
    var params = _a.params, translate = _a.translate, filters = _a.filters, setFilters = _a.setFilters, handleSubmit = _a.handleSubmit;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState("addCard"), curPayComp = _c[0], setCurPayComp = _c[1]; //addCard, payment
    var _d = useState(false), openAddCard = _d[0], setOpenAddCard = _d[1];
    var _e = useState([
        { name: translate("pages.membership.fields.headers.basic"), gens: 10, price1: 10, price2: 100 },
        { name: translate("pages.membership.fields.headers.standard"), gens: 30, price1: 25, price2: 250 },
        { name: translate("pages.membership.fields.headers.advanced"), gens: 50, price1: 40, price2: 400 },
        { name: translate("pages.membership.fields.headers.premium"), gens: translate("pages.membership.fields.details.unlimited"), price1: 100, price2: 1000 },
    ]), subCard = _e[0], setSubCard = _e[1];
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var handleControlLabel = function (e) {
        setFilters(__assign(__assign({}, filters), { security: e.target.checked }));
    };
    return (_jsx(_Fragment, { children: filters &&
            _jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "start", children: [_jsx(Typography, { variant: "body", style: { cursor: "pointer" }, onClick: function () { setOpenAddCard(true); }, children: translate("pages.payment.fields.addNew") }), _jsx(Elements, { stripe: stripePromise, handleSubmit: handleSubmit, opened: openAddCard, closeDialog: function () { setOpenAddCard(false); }, translate: translate, children: _jsx(AddCard, { handleSubmit: handleSubmit, opened: openAddCard, closeDialog: function () { setOpenAddCard(false); }, translate: translate }) })] }) }));
};
export default PaymentComp;
