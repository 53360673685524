var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { alpha, useTheme } from "@mui/material/styles";
var StripeInput = React.forwardRef(function StripeInput(props, ref) {
    var Component = props.component, options = props.options, other = __rest(props, ["component", "options"]);
    var theme = useTheme();
    var _a = React.useState(null), mountNode = _a[0], setMountNode = _a[1];
    React.useImperativeHandle(ref, function () { return ({
        focus: function () { return mountNode.focus(); }
    }); }, [mountNode]);
    return (_jsx(Component, __assign({ onReady: setMountNode, options: __assign(__assign({}, options), { style: {
                base: {
                    color: theme.palette.text.primary,
                    fontSize: "16px",
                    lineHeight: "1.4375em", // 23px
                    fontFamily: theme.typography.fontFamily,
                    "::placeholder": {
                        color: alpha(theme.palette.text.primary, 0.42)
                    }
                },
                invalid: {
                    color: theme.palette.text.primary
                }
            } }) }, other)));
});
export default StripeInput;
