var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import { useDataProvider, useTranslate } from 'react-admin';
import AllOrganizations from './AllOrganization';
import RevenueReport from './RevenueReport';
import YourDashboard from './YourDashborad';
var Dashboard = function (props) {
    var translate = useTranslate();
    var _a = useState(true), showDashboard = _a[0], setShowDashboard = _a[1];
    var _b = useState(false), showAllOrganizations = _b[0], setShowAllOrganizations = _b[1];
    var _c = useState(false), showRevenueReport = _c[0], setShowRevenueReport = _c[1];
    var toggleDashboard = function () {
        setShowDashboard(!showDashboard);
    };
    var toggleAllOrganizations = function () {
        setShowAllOrganizations(!showAllOrganizations);
    };
    var toggleRevenueReport = function () {
        setShowRevenueReport(!showRevenueReport);
    };
    var _d = useState({
        "dashboard": {
            "view_general": false,
            "view_sales": false
        },
        "user": {
            "view_all": false,
            "view_org": false,
            "create_new": false,
            "edit_all": false,
            "edit_org": false,
            "disable_all": false
        },
        "organization": {
            "view_allOrg": false,
            "view_ownOrg": false,
            "create_org": false,
            "disable_allOrg": false,
            "disable_ownOrg": false,
            "edit_all": false,
            "edit_ownOrg": false,
            "edit_allGroup": false,
            "edit_ownGroup": false,
            "add_newGroup": false,
            "disable_allGroup": false,
            "disable_ownGroup": false,
            "disable_orgUser": false,
            "create_orgUser": false
        },
        "subject": {
            "view_all": false,
            "view_org": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "dataExport": {
            "export_all": false,
            "export_org": false,
            "export_retinADProbScores": false
        }
    }), accessDialogData = _d[0], setAccessDialogData = _d[1];
    var dataProvider = useDataProvider();
    useEffect(function () {
        var _a;
        var cachedAuth = (_a = JSON.parse(localStorage.getItem('auth'))) !== null && _a !== void 0 ? _a : {};
        if (cachedAuth.id) {
            dataProvider.getOneWithoutDataJSON("users", { id: cachedAuth.id })
                .then(function (data) {
                setAccessDialogData(function (prevState) { var _a; return (__assign(__assign({}, prevState), (_a = data === null || data === void 0 ? void 0 : data.profile) === null || _a === void 0 ? void 0 : _a.ACL)); });
            })
                .catch(function (error) {
                console.error(error);
            });
        }
    }, []);
    return (_jsxs(Box, { children: [accessDialogData.dashboard.view_sales && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                            marginTop: '1em',
                            padding: '0.2em',
                            borderRadius: '8px',
                            boxShadow: '0 3px 5px 2px rgba(13, 183, 223, .3)',
                            transition: 'all 0.3s ease-in-out',
                            height: '40px',
                        }, children: [_jsx(IconButton, { onClick: toggleRevenueReport, sx: {
                                    color: 'white',
                                    transition: 'transform 0.3s',
                                }, children: showRevenueReport ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) }), _jsx(Box, { sx: {
                                    flexGrow: 1,
                                    ml: 1,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                }, children: translate('pages.dashboard.title.revenueReport') })] }), _jsx(Collapse, { in: showRevenueReport, children: _jsx(RevenueReport, {}) })] })), accessDialogData.dashboard.view_general && (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                            marginTop: '1em',
                            padding: '0.2em',
                            borderRadius: '8px',
                            boxShadow: '0 3px 5px 2px rgba(13, 183, 223, .3)',
                            transition: 'all 0.3s ease-in-out',
                            height: '40px',
                        }, children: [_jsx(IconButton, { onClick: toggleAllOrganizations, sx: {
                                    color: 'white',
                                    transition: 'transform 0.3s',
                                }, children: showAllOrganizations ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) }), _jsx(Box, { sx: {
                                    flexGrow: 1,
                                    ml: 1,
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                }, children: translate('pages.dashboard.title.allOrganizations') })] }), _jsx(Collapse, { in: showAllOrganizations, children: _jsx(AllOrganizations, {}) })] })), _jsxs(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                    marginTop: '1em',
                    padding: '0.2em',
                    borderRadius: '8px',
                    boxShadow: '0 3px 5px 2px rgba(13, 183, 223, .3)',
                    transition: 'all 0.3s ease-in-out',
                    height: '40px',
                }, children: [_jsx(IconButton, { onClick: toggleDashboard, sx: {
                            color: 'white',
                            transition: 'transform 0.3s',
                        }, children: showDashboard ? _jsx(ExpandLessIcon, {}) : _jsx(ExpandMoreIcon, {}) }), _jsx(Box, { sx: {
                            flexGrow: 1,
                            ml: 1,
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                        }, children: translate('pages.dashboard.title.yourOrganization') })] }), _jsx(Collapse, { in: showDashboard, children: _jsx(YourDashboard, {}) })] }));
};
export default Dashboard;
