// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    Form,
    Labeled,
    PrevNextButtons,
    ReferenceField,
    SelectInput,
    TextField,
    Toolbar,
    useRecordContext,
    useTranslate,
    DateInput, minValue,
    DateInput as RADateInput,
    minValue as RAminValue,
    maxValue as RAmaxValue
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Box, Grid, Typography, Link, Button, CircularProgress } from '@mui/material';
import { filter as raFilter } from '../utils/reactAdminComponentWizard'
import { filter } from '../utils/componentWizard';
import SearchIcon from '@mui/icons-material/Search';



interface FilterListProps {
    search: any;
    isLoading: boolean;
    filters: any;
    setFilters: (filters: any) => void;
    params: any;
}

const Spacer = () => <Box mb={1}>&nbsp;</Box>;


const FilterList: React.FC<FilterListProps> = ({ search, isLoading, filters, setFilters, params, translate }) => {


    const SubjectsDatePicker = ({ filters, setFilters, name, label, minWidth,
        minDate, maxDate, size, notNeedRequired, gridBGColor, width }) => {
        let gridStyle: any = { marginY: "1rem", }
        if (gridBGColor) {
            gridStyle['backgroundColor'] = gridBGColor;
            gridStyle['padding'] = "0.6rem";
            gridStyle['borderRadius'] = "10px";
        };

        return (
            <Grid item style={gridStyle}>
                <Form>
                    <RADateInput source={label}
                        // minDate={minDate ?? null}
                        //[back] min and max date not working
                        variant='outlined'
                        validate={RAminValue(new Date('2024-06-06'))}
                        // maxDate={maxDate  ?? null}
                        onChange={(e) => {
                            const newFilter = { ...filters };
                            const date = new Date(e.target.value).toLocaleDateString('en-US');

                            if (date == "Invalid Date") {
                                delete newFilter[name]
                            } else {
                                newFilter[name] = date;
                            }


                            setFilters(newFilter);
                            sessionStorage.setItem(`${window.location.pathname}_filter`, JSON.stringify(newFilter))
                        }}
                        defaultValue={filters?.[name] ? new Date(filters[name]) : null}
                        sx={{
                            opacity: 0.6,
                            width: "100%",
                            '& .MuiInputBase-root': {
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                                // boxShadow: '0px 6px 8px gray',
                                height: "2.7em",
                                fontWeight: 900,
                                fontSize: "1.1em",
                                '& input': {
                                    opacity: 0.7,
                                    paddingTop: '1em',
                                },
                            },
                        }}
                        helperText=""
                    />
                </Form>
            </Grid>
        )
    };

    let sessonStorageJSON = JSON.parse(sessionStorage.getItem(`${window.location.pathname}_filter`)) ?? {}
    let updatedFilters = { ...filters, ...sessonStorageJSON }

    return (

        <Grid container spacing={1} justifyContent="start" >


            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.textField filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.id`)} name="id" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.dropDownList filters={filters} setFilters={setFilters} label={translate(`pages.userList.fields.organization`)} name="organizations" options={params} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.textField filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.groupNum`)} name="groupNum"/>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <filter.textField filters={filters} setFilters={setFilters} label={translate(`pages.organizations.fields.userNum`)} name="userNum" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <SubjectsDatePicker filters={updatedFilters} setFilters={setFilters} label={translate(`pages.organizations.fields.createdAt`)} name="createdAt" 
                    minDate={new Date(2019, 8, 1)} maxDate={new Date()} notNeedRequired={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <SubjectsDatePicker filters={updatedFilters} setFilters={setFilters} label={translate(`pages.organizations.fields.updatedAt`)} name="updatedAt" 
                    minDate={new Date(2019, 8, 1)} maxDate={new Date()} notNeedRequired={true} />
            </Grid>

            {/*  <Grid item alignItems="end" justifyContent="end" >
                <Button variant="contained" disabled={isLoading} onClick={() => { search() }} color="common" startIcon={
                    <SearchIcon
                        sx={{
                            animation: 'pulse 2s infinite ease-in-out',
                            '@keyframes pulse': {
                                '0%': {
                                    transform: 'scale(1)',
                                },
                                '50%': {
                                    transform: 'scale(1.5)',
                                },
                                '100%': {
                                    transform: 'scale(1)',
                                },
                            },
                        }}
                    />
                }
                    sx={{
                        marginTop: "0.5em",
                        height: "3.5em",
                        width: "9em",
                        fontWeight: "900",
                        color: "#0d47a1",
                        borderRadius: '20px',
                        boxShadow: '0px 6px 8px gray',
                        opacity: "0.6",
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                            backgroundColor: '#1976d2',
                            color: 'white',
                            transform: 'scale(1.05)',
                            boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.3)',
                            opacity: "1",
                        },
                        '&:active': {
                            transform: 'scale(0.95)',
                        },
                        '&:hover .MuiSvgIcon-root': {
                            animation: 'pulseHover 1.5s infinite ease-in-out',
                        },
                        '@keyframes pulseHover': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '50%': {
                                transform: 'scale(1.8)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }}>
                    {isLoading ? <CircularProgress size={24} /> : 'Search'}
                </Button>
            </Grid>  */}
        </Grid>
    )
};





export default FilterList;
