var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin';
import { Typography, Grid, Button } from '@mui/material';
import { orgStyles as styles } from './helper/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import GeneralComp from './editDialogComps/General';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GroupComp from './editDialogComps/Group';
import UserComp from './editDialogComps/User';
import CreateGroupDialog from './editDialogComps/GroupHelper/CreateGroupDialog';
import UserEditDialog from '../userPage/EditDialog';
// individual panel component
var PanelComp = function (_a) {
    var icon = _a.icon, text = _a.text, handleOnclick = _a.handleOnclick, backgroundColor = _a.backgroundColor, name = _a.name;
    return (_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", padding: 0.5, style: { cursor: "pointer", backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : "white" }, onClick: function () { handleOnclick(name); }, children: [_jsx(Typography, { variant: "body", sx: { paddingRight: "0.5rem" }, children: icon }), _jsx(Typography, { variant: "body", children: text })] }));
};
var EditComp = function (_a) {
    var params = _a.params, data = _a.data, closeDialog = _a.closeDialog, opened = _a.opened, reloadRecord = _a.reloadRecord, setDialogData = _a.setDialogData, resource = _a.resource, selectedViewOrgName = _a.selectedViewOrgName, dialogData = _a.dialogData, acessControlList = _a.acessControlList, resetParams = _a.resetParams;
    var location = useLocation();
    var navigate = useNavigate();
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var translate = useTranslate();
    var usersAttributeResource = "users/userList";
    var _b = useState({}), groupFilters = _b[0], setGroupFilters = _b[1];
    var _c = useState({}), userFilters = _c[0], setUserFilters = _c[1];
    var _d = useState([]), groupData = _d[0], setGroupData = _d[1];
    var _e = useState(false), createGroupsDialogOpened = _e[0], setCreateGroupsDialogOpened = _e[1];
    var _f = useState({ total: 0, perPage: 25, }), groupPageInfo = _f[0], setGroupPageInfo = _f[1];
    var _g = useState({ total: 0, perPage: 25, }), userPageInfo = _g[0], setUserPageInfo = _g[1];
    var _h = useState([]), userData = _h[0], setUserData = _h[1];
    var _j = useState([]), allUserData = _j[0], setAllUserData = _j[1];
    // const resource = "dataexport";
    var panelCompNames = [{ name: "general", label: translate("pages.organizations.editComp.general"), icon: _jsx(AccountCircleIcon, {}) },
        { name: "groups", label: translate("pages.organizations.editComp.groups"), icon: _jsx(LanguageIcon, {}) },
        { name: "users", label: translate("pages.organizations.editComp.users"), icon: _jsx(SecurityIcon, {}) },
    ];
    var _k = useState({
        "dashboard": {
            "view_general": false,
            "view_sales": false
        },
        "user": {
            "view_all": false,
            "view_org": false,
            "create_new": false,
            "edit_all": false,
            "edit_org": false,
            "disable_all": false
        },
        "organization": {
            "view_allOrg": false,
            "view_ownOrg": false,
            "create_org": false,
            "disable_allOrg": false,
            "disable_ownOrg": false,
            "edit_all": false,
            "edit_ownOrg": false,
            "edit_allGroup": false,
            "edit_ownGroup": false,
            "add_newGroup": false,
            "disable_allGroup": false,
            "disable_ownGroup": false,
            "disable_orgUser": false,
            "create_orgUser": false
        },
        "subject": {
            "view_all": false,
            "view_org": false,
            "create": false,
            "edit": false,
            "delete": false
        },
        "dataExport": {
            "export_all": false,
            "export_org": false,
            "export_retinADProbScores": false
        }
    }), accessDialogData = _k[0], setAccessDialogData = _k[1];
    var _l = useState(true), isLoading = _l[0], setIsLoading = _l[1];
    var _m = useState("general"), curSelectedComp = _m[0], setCurSelectedComp = _m[1]; //profile, language, security
    var _o = useState({}), originalInfo = _o[0], setOriginalInfo = _o[1];
    var _p = useState({}), filters = _p[0], setFilters = _p[1];
    var _q = useState(params), newParams = _q[0], setNewParams = _q[1]; //new params to for choosable usergroup 
    // dialog for user comp
    var _r = useState(false), userDialogOpened = _r[0], setUserDialogOpened = _r[1];
    var _s = useState({}), userDialogData = _s[0], setUserDialogData = _s[1];
    useEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var emptyParams, groupParams, userParams, newGroupData, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    emptyParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: {}
                    };
                    groupParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: groupFilters
                    };
                    userParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: userFilters
                    };
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, dataProvider.getListNoFilter("".concat(resource, "/").concat(data.id, "/groups"), groupParams)];
                case 2:
                    newGroupData = (_c.sent());
                    setGroupData((_b = (_a = newGroupData === null || newGroupData === void 0 ? void 0 : newGroupData.data) === null || _a === void 0 ? void 0 : _a.UserGroup) !== null && _b !== void 0 ? _b : []);
                    // //users data
                    return [4 /*yield*/, handleGetAllUser()];
                case 3:
                    // //users data
                    _c.sent();
                    // console.log('== edit comp datas', newGroupData, newUserData)
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _c.sent();
                    console.log(err_1, '==err');
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5:
                    //update the params - filter usergroups with the correct org
                    resetNewParams();
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var resetNewParams = function () {
        var updatedNewParams = __assign({}, newParams);
        updatedNewParams['userGroups'] = updatedNewParams['userGroups'].filter(function (item) { return item.orgId === data.id; });
        console.log(updatedNewParams, '==updatedNewParams 321', params, newParams);
        setNewParams(updatedNewParams);
    };
    var handleGetAllUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userParams, newUserData;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    userParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: userFilters
                    };
                    return [4 /*yield*/, dataProvider.getListNoFilter("".concat(resource, "/").concat(data.id, "/users"), userParams)];
                case 1:
                    newUserData = (_e.sent());
                    setUserData((_b = (_a = newUserData === null || newUserData === void 0 ? void 0 : newUserData.data) === null || _a === void 0 ? void 0 : _a.User) !== null && _b !== void 0 ? _b : []);
                    // let allUserData = (await dataProvider.getListNoFilter(`users`, emptyParams)); //this is all users
                    setAllUserData((_d = (_c = newUserData === null || newUserData === void 0 ? void 0 : newUserData.data) === null || _c === void 0 ? void 0 : _c.User) !== null && _d !== void 0 ? _d : []);
                    return [2 /*return*/];
            }
        });
    }); };
    // group 
    var searchGroupFilterGroup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var groupParams, newGroupData, err_2;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    groupParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: groupFilters
                    };
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    setIsLoading(false);
                    return [4 /*yield*/, dataProvider.getListNoFilter("".concat(resource, "/").concat(data.id, "/groups"), groupParams)];
                case 2:
                    newGroupData = (_d.sent());
                    setGroupData((_b = (_a = newGroupData === null || newGroupData === void 0 ? void 0 : newGroupData.data) === null || _a === void 0 ? void 0 : _a.UserGroup) !== null && _b !== void 0 ? _b : []);
                    setGroupPageInfo(__assign(__assign({}, groupPageInfo), { total: (_c = newGroupData === null || newGroupData === void 0 ? void 0 : newGroupData.data) === null || _c === void 0 ? void 0 : _c.UserGroupNum }));
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _d.sent();
                    notify("Update fail");
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // saving group page - user list in user group
    var handleSaveGroupUserList = function (rightList, groupId) { return __awaiter(void 0, void 0, void 0, function () {
        var groupParams, saveGroupData, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    groupParams = {
                        data: {
                            userIds: rightList.map(function (item) { return item.id; })
                        }
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataProvider.updateAttribute(resource, groupParams, "".concat(data.id, "/groups"), groupId)];
                case 2:
                    saveGroupData = (_a.sent());
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, searchUserFilterGroup()];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, handleGetAllUser()];
                case 5:
                    _a.sent();
                    notify("Updated groups");
                    setIsLoading(false);
                    return [3 /*break*/, 7];
                case 6:
                    err_3 = _a.sent();
                    notify("Update fail");
                    setIsLoading(false);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    // saving group page - access in user group
    var handleSaveGroupAcess = function (accessDialogData, groupId, name) { return __awaiter(void 0, void 0, void 0, function () {
        var groupParams, saveGroupData, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(accessDialogData, groupId, '==ad', name);
                    groupParams = {
                        data: {
                            access: accessDialogData,
                            name: name
                        }
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataProvider.updateAttribute(resource, groupParams, "".concat(data.id, "/groups"), groupId)];
                case 2:
                    saveGroupData = (_a.sent());
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 3:
                    _a.sent();
                    notify("Updated group");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_4 = _a.sent();
                    notify("Update fail");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // add group
    var reloadAddGroupRecord = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var groupParams, saveGroupData, updatedNewParams, err_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    groupParams = {
                        data: {
                            name: values.name,
                            userIds: [],
                            access: accessDialogData
                        }
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataProvider.createAttribute(resource, groupParams, data.id, "groups")];
                case 2:
                    saveGroupData = (_a.sent());
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, resetParams()];
                case 4:
                    _a.sent();
                    updatedNewParams = __assign({}, newParams);
                    updatedNewParams['userGroups'].push({
                        id: saveGroupData.id, name: saveGroupData.name,
                        value: saveGroupData.id, orgId: data.id
                    });
                    setNewParams(updatedNewParams);
                    // resetNewParams();
                    console.log('==resetParams', saveGroupData, updatedNewParams, '==nn==', {
                        id: saveGroupData.data.id, name: saveGroupData.data.name,
                        value: saveGroupData.data.id, orgId: data.id
                    });
                    notify("Added group");
                    setIsLoading(false);
                    return [3 /*break*/, 6];
                case 5:
                    err_5 = _a.sent();
                    notify("Add fail");
                    setIsLoading(false);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    // add group
    var handleDelGroup = function (groupId) { return __awaiter(void 0, void 0, void 0, function () {
        var groupParams, saveGroupData, err_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    groupParams = {
                        data: {}
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataProvider.deleteAttribute(resource, groupParams, data.id, "groups/".concat(groupId))];
                case 2:
                    saveGroupData = (_a.sent());
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 3:
                    _a.sent();
                    notify("Updated group");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_6 = _a.sent();
                    notify("Update fail");
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // user 
    var searchUserFilterGroup = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userParams, newUserData, err_7;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    userParams = {
                        pagination: { page: 1, perPage: 25 },
                        sort: { field: "id", order: "ASC" },
                        // filter: updatedFilters //[back][0715] resume this
                        filter: userFilters
                    };
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, dataProvider.getListNoFilter("".concat(resource, "/").concat(data.id, "/users"), userParams)];
                case 2:
                    newUserData = (_d.sent());
                    setUserData((_b = (_a = newUserData === null || newUserData === void 0 ? void 0 : newUserData.data) === null || _a === void 0 ? void 0 : _a.User) !== null && _b !== void 0 ? _b : []);
                    setUserPageInfo(__assign(__assign({}, userPageInfo), { total: (_c = newUserData === null || newUserData === void 0 ? void 0 : newUserData.data) === null || _c === void 0 ? void 0 : _c.UserNum }));
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_7 = _d.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // add user
    var userReloadRecord = function (values, dialogType) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedPassword, updatedConfirmpw, params_1, _a, err_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, , 9]);
                    updatedPassword = (!values.password || values.password === "") ? null : values.password;
                    updatedConfirmpw = (!values.confirmpw || values.confirmpw === "") ? null : values.confirmpw;
                    if (updatedPassword != updatedConfirmpw) {
                        notify("Password not the same");
                        return [2 /*return*/];
                    }
                    params_1 = {
                        data: {
                            "username": values.username,
                            "Last_name": values.Last_name,
                            "First_name": values.First_name,
                            "email": values.email,
                            "language": values.language,
                            "gender": values.gender,
                            "organizationIds": [values.editOrganizations],
                            // "userGroups": [values.userGroups],
                            "password": values.password
                        }
                    };
                    if (values.editUserGroups) {
                        params_1.data.userGroupIds = values.editUserGroups;
                    }
                    console.log(values.organizations, values.userGroups, '==poi');
                    _a = dialogType;
                    switch (_a) {
                        case "Create": return [3 /*break*/, 2];
                    }
                    return [3 /*break*/, 7];
                case 2:
                    console.log('==create params', params_1);
                    return [4 /*yield*/, dataProvider.create(usersAttributeResource, params_1)];
                case 3:
                    _b.sent();
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 4:
                    _b.sent();
                    return [4 /*yield*/, searchUserFilterGroup()];
                case 5:
                    _b.sent();
                    return [4 /*yield*/, handleGetAllUser()];
                case 6:
                    _b.sent(); //re-fetch all users
                    notify('User added');
                    _b.label = 7;
                case 7:
                    setUserDialogData(__assign({}, values));
                    setUserDialogOpened(false);
                    return [3 /*break*/, 9];
                case 8:
                    err_8 = _b.sent();
                    console.log(err_8, '==err');
                    refresh();
                    notify('User update fail, please try another email or username');
                    setIsLoading(false);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var openUserEditDialog = function () {
        var _a;
        setUserDialogData({
            dialogType: "Create",
            editOrganizations: (_a = data === null || data === void 0 ? void 0 : data.id) !== null && _a !== void 0 ? _a : null,
        });
        // formik?.current?.resetForm();
        setUserDialogOpened(true);
    };
    //disable user from group
    var handleDisableUser = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
        var params, userResource, err_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log(userId, '==userId');
                    params = {
                        data: {
                            status: "Disabled"
                        }
                    };
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    userResource = "users";
                    return [4 /*yield*/, dataProvider.updateOne(userResource, params, "".concat(userId, "/status"))];
                case 2:
                    _a.sent();
                    setIsLoading(false);
                    //re-fetch all users
                    return [4 /*yield*/, searchGroupFilterGroup()];
                case 3:
                    //re-fetch all users
                    _a.sent();
                    return [4 /*yield*/, searchUserFilterGroup()];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, handleGetAllUser()];
                case 5:
                    _a.sent();
                    notify("disabled user");
                    return [3 /*break*/, 7];
                case 6:
                    err_9 = _a.sent();
                    console.log(err_9, '==err');
                    notify("disable user failed");
                    setIsLoading(false);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Grid, { container: true, display: "flex", flexWrap: "wrap", style: styles.settingsContainer, children: [_jsx(Grid, { item: true, lg: 1.5, md: 2, xs: 12, style: styles.componentContainer, children: panelCompNames.map(function (el, idx) {
                    var backgroundColor;
                    if (el.name === curSelectedComp) {
                        backgroundColor = "#F2F2F2";
                    }
                    return (_jsx(PanelComp, { icon: el.icon, text: el.label, name: el.name, backgroundColor: backgroundColor, handleOnclick: setCurSelectedComp }));
                }) }), _jsxs(Grid, { item: true, lg: 10, md: 9, xs: 12, style: styles.componentContainer, children: [_jsxs(Grid, { item: true, display: "flex", alignItems: "center", justifyContent: "space-between", children: [_jsxs(Grid, { item: true, display: "flex", flexWrap: "wrap", alignItems: "center", children: [_jsx(Typography, { variant: "h4", sx: { paddingRight: '10px', marginX: "1rem", marginY: curSelectedComp == "general" ? "1rem" : 0, }, children: translate("pages.organizations.editComp.".concat(curSelectedComp)) }), curSelectedComp != "general" && acessControlList.organization["create-org"] &&
                                        _jsx(Button, { variant: "contained", startIcon: _jsx(AddBoxIcon, {}), onClick: function () {
                                                if (curSelectedComp === "groups") {
                                                    setCreateGroupsDialogOpened(true);
                                                }
                                                else {
                                                    openUserEditDialog();
                                                }
                                            }, children: curSelectedComp === "groups"
                                                ? translate("pages.organizations.addNewGroup")
                                                : translate("pages.organizations.addNewUser") })] }), _jsxs(Grid, { item: true, display: "flex", children: [(curSelectedComp === "general" && (acessControlList.organization["edit-all"] || acessControlList.organization["edit-ownOrg"])) &&
                                        _jsx(Typography, { variant: "body1", sx: styles.saveText, onClick: function () { reloadRecord(data, "Edit"); }, children: translate("common.save") }), _jsx(Typography, { variant: "body1", sx: __assign(__assign({}, styles.saveText), { paddingLeft: "2rem" }), onClick: closeDialog, children: translate("common.back") })] })] }), createGroupsDialogOpened && _jsx(CreateGroupDialog, { opened: createGroupsDialogOpened, params: newParams, 
                        // openDialog={openDialog}
                        translate: translate, closeDialog: function () { setCreateGroupsDialogOpened(false); }, reloadRecord: reloadAddGroupRecord }), userDialogOpened && _jsx(UserEditDialog, { disableOrg: true, title: translate("pages.organizations.addNewUser"), opened: userDialogOpened, data: userDialogData, closeDialog: function () { setUserDialogOpened(false); }, reloadRecord: userReloadRecord, translate: translate, params: newParams }), curSelectedComp === "general" &&
                        _jsx(GeneralComp, { params: newParams, translate: translate, filters: filters, setFilters: setFilters, setDialogData: setDialogData, closeDialog: closeDialog, opened: opened, reloadRecord: reloadRecord, data: data, acessControlList: acessControlList }), curSelectedComp === "groups" &&
                        _jsx(GroupComp, { params: newParams, translate: translate, filters: groupFilters, setFilters: setGroupFilters, dialogData: dialogData, setDialogData: setDialogData, handleSaveGroupUserList: handleSaveGroupUserList, handleSaveGroupAcess: handleSaveGroupAcess, closeDialog: closeDialog, opened: opened, isLoading: isLoading, setIsLoading: setIsLoading, userData: userData, accessDialogData: accessDialogData, setAccessDialogData: setAccessDialogData, allUserData: allUserData, selectedViewOrgName: selectedViewOrgName, reloadRecord: reloadRecord, generalData: data, data: groupData, searchFilterGroup: searchGroupFilterGroup, resource: resource, handleDelGroup: handleDelGroup, paginationInfo: groupPageInfo, acessControlList: acessControlList }), curSelectedComp === "users" &&
                        _jsx(UserComp, { params: newParams, translate: translate, filters: userFilters, setFilters: setUserFilters, setDialogData: setDialogData, closeDialog: closeDialog, opened: opened, isLoading: isLoading, setIsLoading: setIsLoading, handleDelete: handleDisableUser, reloadRecord: reloadRecord, generalData: data, data: userData, searchFilterGroup: searchUserFilterGroup, paginationInfo: userPageInfo, acessControlList: acessControlList })] })] }));
};
export default EditComp;
